<template>
    <Overlay  :language="language" v-if="displayModal" :favorite="save" :title="title" :visible="displayModal" @remove="displayModal=false"/>
    
    <div v-if="allIsLoaded==false && (allPhotosLoaded==false && firstLoad==true)" class="loading-overlay">
    
        <div class="message" v-if="language=='fr'">
            Chargement du configurateur 3D.
        </div>
        <div class="message" v-else>
            3D Configurator loading.
        </div>

    </div>

    <div class="page-template-collab collection">
      
        <div class="collab-section product">
            <span id="trigger1"></span>
            <div class="title-top contenumob">
                {{ glass.name }}
            </div>
            <div class="left" id="left-content" :class="{ fixed: fullScreen, top:isTop }" :style="fullScreen==true && 'top:-'+(100-100)+'px !important;'">
                <content-loader  v-if="loadingPhotos==true || allIsLoaded==false || allPhotosLoaded==false" viewBox="0 0 300 300" :class="{ontopload:allPhotosLoaded == false && allIsLoaded==true && loadingPhotos==false}">
                </content-loader>
                
                <CollabSectionLeft :gravure="gravure" :photosLoaded="photosLoaded" :allIsLoaded="allIsLoaded" :loaded="loaded"
                    :displayTextPreview="displayTextPreview" :photo="photo" :photos="photos" :fullScreen="fullScreen"
                    @fullScreenToggleEv="fullScreen = !fullScreen"
                    :slider="slider"
                    @hideHoverPhotoEv="hideHoverPhoto"
                    @calculatePhotoEv="calculatePhoto"
                    @finishLoadLayerEv="finishLoadLayer"
                    @startLoadLayerEv="startLoadLayer"
                    :glass="glass"

                    :layersLoaded="layersLoaded"
                    :alreadyLoaded="alreadyLoaded"
                    
                    :loadingGlassOptions="loadingGlassOptions"
                    :loadingGlassTypes="loadingGlassTypes"
                    :loadingPhotos="loadingPhotos"
                    :currentBranchPhoto="currentBranchPhoto"
                    :currentBranchPhotoPresets="currentBranchPhotoPresets"
                    :loadingScans="loadingScans"
                    :loadingSizes="loadingSizes"
                    :loadingTintCategories="loadingTintCategories"
                    :loadingTints="loadingTints"
                    :loadingColors="loadingColors"
                    :loadingTextures="loadingTextures"
                    :loadingTextureStyles="loadingTextureStyles"
                    :loadingRecommendedSize="loadingRecommendedSize"
                    :loadingReviews="loadingReviews"
                    :loadingGlass="loadingGlass"
                    :loadingBranches="loadingBranches"
                    :idGlass="glass.id"
                    :urlPhotoHover="urlPhotoHover"
                    :hoverPhotoDisplay="hoverPhotoDisplay"
                    :urlPhotoSlider="urlPhotoSlider"
                    :orderPhotoHover="orderPhotoHover"
                    :photoDatas="photoDatas"
                    :photoHoverDatas="photoHoverDatas"
                    :fiveSlides="fiveSlides"
                />
            </div>

            
                <CollabSectionRight 
                :hasTexture="hasTexture"
                :hasTemples="hasBranch"
                :branchMaterial="branchMaterial"
                    :language="language"
                    :community="community"
                    :model_parts="model_parts"
                    :branchTextureEnabled="branchTextureEnabled"
                    :loaded="loaded" :glass="glass" :average="average" :reviews="reviews"
                    :current_price="current_price" :togglePosition="togglePosition" :activeToggle="activeToggle"
                    :userScans="userScans" :selectedScan="selectedScan" :recommandedSize="recommandedSize"
                    :sizes="sizes" :activeSize="activeSize" :cart="cart" :displayScans="displayScans"
                    :displayHowItWorkPopup="displayHowItWorkPopup" :activeSizePopup="activeSizePopup"
                    :activeGlassType="activeGlassType" :activeGlassTypeName="activeGlassTypeName"
                    :activeGlassTypePrice="activeGlassTypePrice"
                    :displayGlassTypes="displayGlassTypes" :glass_types="glass_types"
                    :activeGlassOptionName="activeGlassOptionName" :glass_options="glass_options" 
                    :activeGlassOptionPrice="activeGlassOptionPrice"
                    :displayGlassOptions="displayGlassOptions" :displayTintChoice="displayTintChoice"
                    :activePersonnalisation="activePersonnalisation" :underlinePosition="underlinePosition"
                    :activeTexture="activeTexture" :activeTextureStyle="activeTextureStyle"
                    :activeBranchTexture="activeBranchTexture"
                    :activeBranchColor="activeBranchColor"
                    :activeTextureObject="activeTextureObject" :activeStyle="activeStyle"
                    :activeBranchCategory="activeBranchCategory"
                    :activeTintCategory="activeTintCategory" :activeTint="activeTint" :activeBranch="activeBranch"
                    :displayLoadingShare="displayLoadingShare" :localStorage="localStorage"
                    :tint_categories="tint_categories" :actualTints="actualTints" :activeGlassOption="activeGlassOption"
                    :activeGlassOptionIndex="activeGlassOptionIndex" :activeColor="activeColor"
                    :loadingGlassOptions="loadingGlassOptions"
                    :loadingGlassTypes="loadingGlassTypes"
                    :loadingPhotos="loadingPhotos"
                    :loadingScans="loadingScans"
                    :loadingSizes="loadingSizes"
                    :loadingTintCategories="loadingTintCategories"
                    :loadingTints="loadingTints"
                    :loadingColors="loadingColors"
                    :loadingTextures="loadingTextures"
                    :loadingTextureStyles="loadingTextureStyles"
                    :loadingRecommendedSize="loadingRecommendedSize"
                    :loadingReviews="loadingReviews"
                    :loadingGlass="loadingGlass"
                    :loadingBranches="loadingBranches"
                    :tints="tints"
                    :errors_addtocart="errors_addtocart"
                    :branchs="branchs"
                    :customization="customization"
                    :activeTints="activeTints"
                    :displayOnMesurePopup="displayOnMesurePopup"
                    :showColorPopup="showColorPopup"
                    :showTexturePopup="showTexturePopup"
                    :showGlassTypePopup="showGlassTypePopup"
                    :favorite="favorite"

                    :showGlassOptionPopup="showGlassOptionPopup"
                    @hideGlassOptionPopupEv="hideGlassOptionPopup"
                    @displayGlassOptionPopupEv="displayGlassOptionPopup"

                    @disableBranchTextureEv="disableBranchTexture"
                    @enableBranchTextureEv="enableBranchTexture"
                    @hideOnMesurePopupEv="hideOnMesurePopup"
                    @hideGlassTypePopupEv="hideGlassTypePopup"
                    @hideColorPopupEv="hideColorPopup"
                    @hideTexturePopupEv="hideTexturePopup"
                    @resetColorsEv="resetColors"
                    @hoverBranchEv="hoverBranch"
                    @hoverColorEv="hoverColor"
                    @hoverBranchColorEv="hoverBranchColor"
                    @hoverBranchTextureEv="hoverBranchTexture"
                    
                    @displayTexturePopupEv="displayTexturePopup"

                    @hoverTextureEv="hoverTexture"
                    @hoverTintEv="hoverTint"
                    @hoverTextureStyleEv="hoverTextureStyle"
                    @hideHoverPhotoEv="hideHoverPhoto"
                    @scrollToReviewsEv="scrollToReviews" @toggleSelectEv="toggleSelect" @selectSizeEv="selectSize"
                    @displayScansEv="displayScans = !displayScans" @selectScanEv="selectScan"
                    @displayAppPopupEv="displayAppPopup" @displayLoginEv="displayLogin"
                    @displayHowItWorkEv="displayHowItWork" @displayHowItWorkPopupToFalseEv="displayHowItWorkPopupToFalse"
                    @selectSizePopupEv="selectSizePopup" @displayGlassTypesEv="displayGlassTypes = !displayGlassTypes"
                    @selectGlassTypeEv="selectGlassType"
                    @displayGlassOptionsEv="displayGlassOptions = !displayGlassOptions"
                    @selectGlassOptionEv="selectGlassOption" @selectPersonnalisationEv="selectPersonnalisation"
                    @selectBranchTextureEv="selectBranchTexture"
                    @selectBranchColorEv="selectBranchColor"
                    @selectTextureEv="selectTexture" @selectTextureStyleEv="selectTextureStyle"
                    @selectStyleEv="selectStyle" @selectColorEv="selectColor" @selectTintCategoryEv="selectTintCategory"
                    @selectTintEv="selectTint" @selectBranchEv="selectBranch" @gravureEv="gravureFn"
                    @selectBranchCategoryEv="selectBranchCategory"
                    @displayColorPopupEv="displayColorPopup"
                    @displayGlassTypePopupEv="displayGlassTypePopup"
                    @addToCartEv="addToCart" @displaySharePopupEv="displaySharePopup" @saveGlassEv="saveGlass" />

           
        </div>



        <div v-show="allIsLoaded" class="bottom-product-collection">
            

            <CollectionContent :language="language" :glass="glass" />

            <div class="contenudesk"><br /><br /><br /><br /><br /><br /><br /></div>

            <CollectionEnd :language="language" :displayModalMenu="displayModalMenu" :urlPhotoSlider="urlPhotoSlider" :glass="glass" :photos="photos" :favoriteShare="favoriteShare"
                :displayShare="displayShare" :photoDatas="photoDatas" />

        </div>
    </div>
</template>

<script>
import jQuery from 'jquery';
import emitter from 'tiny-emitter/instance'
import { APIRequest } from "@simb2s/senseye-sdk"
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"
import { defineComponent } from 'vue'
import { ContentLoader } from 'vue-content-loader'
import ScrollMagic  from 'ScrollMagic';
// Import children
import CollabSectionRight from "../components/children/CollectionGlass/CollabSectionComponentRight.vue"
import CollabSectionLeft from "../components/children/CollectionGlass/CollabSectionComponentLeft.vue"
import CollectionContent from '../components/children/CollectionGlass/CollectionContentComponent.vue';
import CollectionEnd from "../components/children/CollectionGlass/CollectionEndComponent.vue";
import Overlay from "../components/overlay/OverlayComponent.vue"
import { useSettingsStore } from '@/store/SettingsStore.js';
import parseJwt from '../utils/jwt/extractPayload';
import { useMeta } from 'vue-meta'

export default defineComponent({
    name: 'Collection',
    components: {
        CollabSectionRight,
        CollabSectionLeft,
        CollectionContent,
        CollectionEnd,
        ContentLoader,
        Overlay
    },
    props:["displayModalMenu","language"],
    computed: {
        collectionWatch: function () {
            return [this.activeTexture, this.activeBranchTexture, this.activeTint, this.activeColor, this.activeBranch, this.activeTextureStyle, this.activeBranchColor, this.allIsLoaded]
        },
        current_price: function () {

            if (this.gravure != '' && this.gravure != null) {
                let price = this.glass.price * 1 + this.activeGlassOptionPrice * 1 + this.activeGlassTypePrice * 1 + this.activeColorPrice * 1 + this.activeTexturePrice * 1 + this.activeBranchPrice * 1 + this.activeTintPrice * 1 + 10 * 1
                if (this.activeTextureStylePrice != 0 && this.activeTextureStylePrice != null) {
                    price += this.activeTextureStylePrice
                }
                return price
            }
            else {
                let price = this.glass.price * 1 + this.activeGlassOptionPrice * 1 + this.activeGlassTypePrice * 1 + this.activeColorPrice * 1 + this.activeTexturePrice * 1 + this.activeBranchPrice * 1 + this.activeTintPrice * 1
                if (this.activeTextureStylePrice != 0 && this.activeTextureStylePrice != null) {
                    price += this.activeTextureStylePrice
                }
                return price
            }

        },
       
    },
  
    watch: {

       
        photoDatas:function() {
            if(this.allIsLoaded==true)
            {

                this.photosLoaded=false
                
                
                if(this.firstLoad==true)
                {
                    this.firstLoad=false
                }
                else
                {
                    console.log('photo datas triggered')
                    let different=false
                    // each scene
                    let max=6
                    if(this.glassId==26)
                    {
                        max=4
                    }
                    for(let i=1;i<max;i++)
                    {
                        // each render
                        
                        for(let j=0;j<this.photoDatas['scene'+i].length;j++)
                        {
                           
                            console.log(this.photoDatas['scene'+i][j].render)
                            if(this.photoDatas['scene'+i][j].render!=this.oldPhotoDatas['scene'+i][j].render)
                            {
                                different=true
                            }

                        }


                    }
                    // ICI CHECK SI LES RENDERS ONT CHANGE OU PAS
                   
                    if(different==true)
                    {
                        console.log('different')
                        this.allPhotosLoaded=false
                        for(let q=0;q<this.layersLoaded.length;q++)
                        {
                            //this.layersLoaded[q] -= 1
                        }

                        for(let q=0;q<this.alreadyLoaded.length;q++)
                        {
                            this.alreadyLoaded[q] = false
                        }
                    }
                    else {
                        console.log('pas different')
                        //this.allPhotosLoaded=false
                        for(let q=0;q<this.layersLoaded.length;q++)
                        {
                            //this.layersLoaded[q] -= 1
                        }

                        for(let q=0;q<this.alreadyLoaded.length;q++)
                        {
                            this.alreadyLoaded[q] = false
                        }
                    }
                    //
                    
                    //
                }
                
                // for(let j=0;j<this.photoDatas.length;j++)
                // {
                //     this.alreadyLoaded[j]=false
                //     //this.layersLoaded[j]=0
                // }
           
                this.photosLoaded=true
            }
        },
        
        fullScreen: function () {

            if(this.fullScreen==true)
            {
                jQuery('body').css('overflow-y','hidden');
            }
            else
            {
                jQuery('body').css('overflow-y','auto');
            }
        },

        activeScan: async function () {
            await this.getRecommendedSize()
        },
        language : function() {
            document.location.reload()
        },
        collectionWatch: async function () {
            
         
           
            if (this.loaded==true || this.favorite != null) {
                this.loadingColors=true
             
                // this.loadingPhotos=true
                // this.photosLoaded = false
                await this.calculatePhoto()
                //await this.calculateBranchPhoto()

                if(this.favorite != null)
                {
                    this.photosLoaded = true
                    this.loadingPhotos=false
                }
               
                
                this.loadingColors=false
               //this.allPhotosLoaded=true
            }

            if(this.$route.params.favorite)
            {
                this.photosLoaded = true
                this.loadingPhotos=false
            }
           

            //this.allPhotosLoaded=true

            
        }
    },
    data() {
        return {
            layersLoaded:[],
            alreadyLoaded:[],
            hasBranch:false,
            branchMaterial:'metal',
            hasTexture:false,
            associateTemples:true,
            toTop:0,
            model_parts:null,
            isTop:true,
            useSettings:useSettingsStore(),
            independantBranch:false,
            visible: false,
            showGlassOptionPopup:false,
            showGlassTypePopup:false,
            photosLoaded:false,
            allIsLoaded:false,
            lastWindowY:null,
            controller:null,
            tween: null,
            scene:null,
            title: "",
            sizes: null,
            community:false,
            window:null,
            displayHowItWorkPopup: false,
            displayOnMesurePopup: false,
            showColorPopup: false,
            showTexturePopup:false,
            reviews: null,
            fullScreen: false,
            fiveSlides:true,
            average: 0,
            total_reviews: 0,
            displayShare: false,
            displayLoadingShare: true,
            saved: false,
            photosLoaded: true,
            favoriteShare: null,
            customization:{
                model_parts:{}
            },
            tinySliderOptions: {
                container: '.my-slider',
                mouseDrag: true,
                navPosition: 'bottom',
                loop: false,
                items: 1,
                gutter: 0,
                swipeAngle: 45,
                nav: true,
                controls: true,
                prevButton: '#prev',  // Node reference
                nextButton: '#next',
                startIndex: 0,
            },
            creationSliderOptions: {
                container: '.slider-creations',
                mouseDrag: true,
                navPosition: 'bottom',
                autoplay: true,
                speed: 1000,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                autoplayButton: false,
                loop: true,
                items: 2,
                center: true,
                gutter: 25,
                swipeAngle: 45,
                nav: false,
                controls: true,
                prevButton: '#prev-creation',  // Node reference
                nextButton: '#next-creation',
                startIndex: 0,
                responsive: {
                    760: {
                        gutter:20,
                        items: 4
                    }
                },
            },
            slider_creation: null,
            optionsSelected: false,
            max: 20,
            currentIndex: 0,
            loaded: false,
            displayTintChoice: false,
            activeTintCategory: 'all',
            activeBranchCategory: 'all',
            currentPhoto: '',
            displayTextPreview: false,
            glass_types: [],
            tint_categories: [],
            glass_options: [],
            displayGlassTypes: false,
            displayGlassOptions: false,
            glass: [],
            gravure: '',
            activeTextureStyleObject: null,
            activeTextureStyle: null,
            activeTextureStylePrice: 0,
            activeTextureObject: null,
            activeTexture: null,
            activeColor: null,
            activeBranchColor: null,
            activeBranchTexture: null,
            activeBranch: null,
            activeTint: null,
            activeTexturePrice: 0,
            activeColorPrice: 0,
            activeBranchPrice: 0,
            activeTintPrice: 0,
            controller: null,
            activePersonnalisation: 'color',
            tween: null,
            scene: null,
            email: null,
            currentOption: 'colors',
            displayModal: false,
            currentImage: '',
            currentColor: 'color0',
            showDevices: false,
            activeToggle: 'size',
            activeStyle: 0,
            activeSize: 'onesize',
            activeSizePopup: 'm',
            activeGlassTypePrice: 0,
            activeGlassOptionIndex: null,
            activeGlassOptionPrice: 0,
            activeGlassTypeName: 'Type de verre',
            activeGlassType: 'nocorrection',
            activeTints:null,
            photo: '',
            currentPhotos: [],
            photos: [],
            underlinePosition: 0,
            slider: null,
            togglePosition: 50,
            glassId: null,
            userScans: [],
            selectedScan: 'Sélectionnez',
            activeScan: null,
            displayScans: false,
            activeGlassOption: null,
            activeGlassOptionName: 'Option de verre',
            cart: null,
            actualTints: [],
            favorite: null,
            favoriteId: null,
            loadingGlassOptions:true,
            loadingGlassTypes:true,
            loadingPhotos:true,
            loadingScans:true,
            loadingSizes:true,
            loadingTintCategories:true,
            loadingTints:false,
            allPhotosLoaded:false,
            loadingColors:true,
            loadingTextures:true,
            loadingTextureStyles:false,
            loadingRecommendedSize:true,
            loadingReviews:true,
            loadingGlass:true,
            loadingBranches:true,
            recommandedSize: 'M',
            errors_addtocart:{},
            orderPhotoHover:0,
            currentBranchPhoto:null,
            currentBranchPhotoPresets:null,
            urlPhotoHover:'',
            oldPhotoDatas:null,
            photoDatas:null,
            firstLoad:true,
            branchTextureEnabled:true,
            photoHoverDatas:null,
            urlPhotoSlider:'?glass='+this.$route.params.id+'&color=6&texture=4&branch=4&tint=5',
            hoverPhotoDisplay:false
        }
    },
    
    methods: {
        selectDeviceCollectionGlass() {

            this.activeSize='onmeasure'
            this.toggleSelect('custom');

            this.loaded = true
        },
        async reloadUserContent () {
            this.loaded = false
            this.loadingScans=true
            this.localStorage = localStorage;
            await this.getUserScans();
            if (this.userScans.length > 0) {
                if(this.glass.id!=30)
                {

                
                    this.toggleSelect('custom');
                    for (let i = 0; i < this.userScans.length; i++) {
                        if (this.userScans[i].default_scan == true) {
                            this.selectedScan = this.userScans[i].name
                            this.activeScan = this.userScans[i].id
                        }
                    }
                }

            }
            else {
                // display popup no scan
                //emitter.emit('display-popup-noscan');
            }
            this.loaded = true
            this.loadingScans=false
        },
        async reloadUserContentSaving () {
            this.loaded = false
            this.localStorage = localStorage;
            this.loadingScans=true
            await this.getUserScans();
            if (this.userScans.length > 0) {
                if(this.glass.id!=30)
                {
                    this.toggleSelect('custom');
                    let default_scan=false
                    for (let i = 0; i < this.userScans.length; i++) {
                        if (this.userScans[i].default_scan == true) {
                            this.selectedScan = this.userScans[i].name
                            this.activeScan = this.userScans[i].id
                            default_scan=true
                        }
                    }
                }
                if(default_scan==false){
                    this.selectedScan = this.userScans[0].name
                    this.activeScan = this.userScans[0].id
                }
            }

            this.displayLoadingShare = true
            let options = {}
            options.branch = {}
            options.glass = {}
            options.glass.id = this.glass.id
            options.amount = this.current_price
            options.branch.id = this.activeBranch
            options.color = {}
            options.color.id = this.activeColor
         

            if(this.activeTextureStyle!=null) {
                options.texture_style = {}
                options.texture_style.id = this.activeTextureStyle
            }
           
            //options.branch_color = {id:this.activeBranchColor}
            if (localStorage.id_user_senseye_auth) {
                options.user = {}
                options.user.id = localStorage.id_user_senseye_auth
            }
            if (this.activeTint != null) {
                options.tint = {}
                options.tint.id = this.activeTint
            }
            else {
                options.tint = null
            }

            if (this.activeGlassType && this.activeGlassType != "nocorrection") {
                options.glass_type = {}
                options.glass_type.id = this.activeGlassType
            }
            else {
                options.glass_type = null
            }
            if (this.activeGlassOption) {
                options.glass_option = {}
                options.glass_option.id = this.activeGlassOption
            }
            else {
                options.glass_option = null
            }
            options.engraving_text = this.gravure

            if (this.activeScan != null) {
                options.scan = {}
                options.scan.id = this.activeScan
                options.size = null
            }
            else {
                options.scan = null
                options.size = this.activeSize
            }

            options.order_item_customizations=this.calculateOrderItemCustom()
            let favorite = await APIRequest.create('favorites', options)
            this.favoriteId = favorite.id
            this.saved = true
            this.displayLoadingShare = false
            this.save=true
            this.showModal('Le produit a bien été enregistré !')
            this.loaded = true
            this.loadingScans=false

        },
        startLoadLayer(indexscene){

            

            let indexscenereal=indexscene.replace('scene','')
                   
            indexscenereal-=1
            if(this.alreadyLoaded[indexscenereal]==true)
            {
                if(this.photoDatas[indexscene])
                {

                    console.log('start laod layer')
                    console.log(indexscene)
                    console.log(indexscenereal)
                    console.log(this.layersLoaded[indexscenereal])
                    

                    if(!this.layersLoaded[indexscenereal])
                    {
                        this.layersLoaded[indexscenereal]=0
                    }
                    else
                    {
                        this.layersLoaded[indexscenereal] -= 1

                        if(this.layersLoaded[indexscenereal]<0)
                        {
                            this.layersLoaded[indexscenereal]=0
                        }
                    }


                    console.log(this.layersLoaded[indexscenereal])

                
                }
            }
        },
        finishLoadLayer(indexscene){

           
            if(this.photoDatas[indexscene])
            {

                
                let indexscenereal=indexscene.replace('scene','')
                indexscenereal-=1

                

                if(!this.layersLoaded[indexscenereal])
                {
                    this.layersLoaded[indexscenereal]=0
                }

                this.layersLoaded[indexscenereal] += 1

                let nummax

                if(this.glassId==22)
                {
                    nummax = this.photoDatas[indexscene].length - 2
                }
                else
                {
                    nummax = this.photoDatas[indexscene].length
                }

                if(this.layersLoaded[indexscenereal]>nummax)
                {
                    this.layersLoaded[indexscenereal]=nummax
                }
               

            
                if(this.layersLoaded[indexscenereal]>=nummax)
                {
                
                    this.alreadyLoaded[indexscenereal]=true
                    let allPhotosLoaded=true

                    for(let p=0;p<Object.keys(this.photoDatas).length;p++)
                    {
        
                        if(this.alreadyLoaded[p]!=true)
                        {
                            allPhotosLoaded=false
                        }
                    }
               
                    this.allPhotosLoaded=allPhotosLoaded
                    
                }
            }

        
            
            
            
        },
       async disableBranchTexture(){
            this.branchTextureEnabled=false
            this.customization.model_parts['temples'].texture.id=4
            await this.calculatePhoto()
        },
       async enableBranchTexture(){
            this.branchTextureEnabled=true
            this.customization.model_parts['temples'].texture.id=this.customization.model_parts['frame'].texture.id
            await this.calculatePhoto()
        },
        async calculateBranchPhoto  () {


        if(this.customization.model_parts) {

            if(this.customization.model_parts['temples']) {
            
            
               

                let request = "?color=" +  this.customization.model_parts['temples'].color.id
                let branchs = await APIRequest.list_with_request('branches', request)
             
                
                
                //setGlassViewPhoto([branchs.data[0].photo]);
                //setCurrentBranchPhoto(branchs.data[0].photo);
                this.currentBranchPhoto=branchs[0][0].photo

            
                
            }
            else {
                if(this.customization.model_parts['frame']) {
                    
                    let request = "?color=" +  this.customization.model_parts['frame'].color.id
                    let branchs = await APIRequest.list_with_request('branches', request)
                
                    // let branchs = await CartApi.getBranches(
                    //     handleUrlWithParam({
                    //     color: glassesPersonal.model_parts['frame'].color.id,
                    //     })
                    // );

                    if(this.glass.id==26 && this.customization.model_parts['frame'].color.id==19) {
                        //setGlassViewPhoto([branchs.data[2].photo]);
                        this.currentBranchPhoto=branchs[0][2].photo
                        //setCurrentBranchPhoto(branchs.data[2].photo);
                    }
                    else {

                    //setGlassViewPhoto([branchs.data[0].photo]);
                    //setCurrentBranchPhoto(branchs.data[0].photo);
                    this.currentBranchPhoto=branchs[0][0].photo
                    }
                
                }
            
            }
        }



        },
        async calculatePhoto() {

   

            let request_photo='?glass='+this.glassId+"&branch=4"
    

            for(let i=0;i<this.model_parts.length;i++) {

                if(this.model_parts[i].type=='color') {
                    if(this.model_parts[i].render_slug=="frame")
                    {
                        if(this.customization.model_parts)
                        {
                            
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                            }
                            else {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                                
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                            
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                             }
                            else {
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                           
                            request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                        }
                           // request_photo+='&color_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].color.id : this.model_parts[i].material=="plastic" ? 10 : 19
                        //params['color_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].color.id : this.model_parts[i].material=="plastic" ? 10 : 19
                        
                        
                        
                    
                    }
                    
                    else {
                        if(this.model_parts[i].material=="plastic")
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=10"
                            //params['color_'+this.model_parts[i].render_slug]=10
                        }
                        else
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=19"
                            //params['color_'+this.model_parts[i].render_slug]=19
                        }
                        
                        request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                        //params['texture_style_'+this.model_parts[i].render_slug]=0
                        
                    }
                }

                else if(this.model_parts[i].render_slug=="temples")
                {
                    if(this.customization.model_parts)
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                            }
                            else {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                              
                            }
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                           
                        }
                        //request_photo+='&color_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].color.id : this.model_parts[i].material=="plastic" ? 10 : 23
                        //params['color_'+this.model_parts[i].render_slug]=
                    }
                    else 
                    {
                    
                        if(this.model_parts[i].material=="plastic")
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=10"
                            //params['color_'+this.model_parts[i].render_slug]=10
                        }
                        else
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=23"
                            //params['color_'+this.model_parts[i].render_slug]=23
                        }
                    
                    }
                    request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                
                    //params['texture_style_'+this.model_parts[i].render_slug]=0
                    
                }

                else {
                    if(this.customization.model_parts)
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                            }
                            else {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                                
                            }
                    
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                           
                           
                        }
                        //request_photo+='&color_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].color ? : this.model_parts[i].material=="plastic" ? 10 : 19 : 
                        //params['color_'+this.model_parts[i].render_slug]=
                    }
                    else {
                        request_photo+='&color_'+this.model_parts[i].render_slug+"=19"
                    //params['color_'+this.model_parts[i].render_slug]=19
                    }

                    
                }

                // if(this.customization.model_parts[this.model_parts[i].render_slug]) {

                    

                //     if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                //         request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                //     //params['texture_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture.id

                //     }
                //     else {
                //         request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                //     //params['texture_'+this.model_parts[i].render_slug]=4
                //     }

                // }
                // else {
                //     request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                //     //params['texture_'+this.model_parts[i].render_slug]=4
                // }

                
            }
            else if(this.model_parts[i].type=='texture_color') {


                if(this.model_parts[i].render_slug=="frame")
                {
                    if(this.customization.model_parts)
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                            }
                            else {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                                
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                            
                                 request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                            //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                            }
                            else {
                                request_photo+='&texture_'+this.model_parts[i].render_slug+"=1"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                            
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                            }
                            else {
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                           
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                            
                                request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                            }
                            else {
                            request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                            //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                            
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                            }
                            else {
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                        }
                        
                    
                    }
                    
                    else {
                        if(this.model_parts[i].material=="plastic")
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=10"
                            //params['color_'+this.model_parts[i].render_slug]=10
                        }
                        else
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=19"
                            //params['color_'+this.model_parts[i].render_slug]=19
                        }
                        request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                        request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                        //params['texture_style_'+this.model_parts[i].render_slug]=0
                    
                    }
                }
                else if(this.model_parts[i].render_slug=="temples")
                {
                    
                    if(this.customization.model_parts)
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                            }
                            else {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                                }
                                
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                            
                                request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                            }
                            else {
                                request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                            
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id
                                //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                             }
                            else {
                                request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                                //params['texture_style_'+this.model_parts[i].render_slug]=0
                            }
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                           
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                            
                            request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                            //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                        }
                        else {
                            request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                            //params['texture_style_'+this.model_parts[i].render_slug]=0
                        }
                        if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                        
                            request_photo+='&texture_style_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id
                            //params['texture_style_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id

                         }
                        else {
                            request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                            //params['texture_style_'+this.model_parts[i].render_slug]=0
                        }
                        }
                       
                    //params['color_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].color.id : this.model_parts[i].material=="plastic" ? 10 : 23
                    }
                    else 
                    {
                    

                        if(this.model_parts[i].material=="plastic")
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=10"
                            //params['color_'+this.model_parts[i].render_slug]=10
                        }
                        else
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+"=23"
                            //params['color_'+this.model_parts[i].render_slug]=23
                        }
                    
                    }

                    request_photo+='&texture_style_'+this.model_parts[i].render_slug+"=0"
                    //params['texture_style_'+this.model_parts[i].render_slug]=0
                    
                }

                else {
                    if(this.customization.model_parts)
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                        {
                            request_photo+='&color_'+this.model_parts[i].render_slug+'='+this.customization.model_parts[this.model_parts[i].render_slug].color.id
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                            
                        }  
                    }
                    else {
                        if(this.model_parts[i].material=="plastic")
                            {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=10'
                            }
                            else {
                                request_photo+='&color_'+this.model_parts[i].render_slug+'=19'
                            }
                   // params['color_'+this.model_parts[i].render_slug]=this.model_parts[i].material=="plastic" ? 10 : 19
                    }

                    
                }

                // if(this.customization.model_parts)
                // {

                

                //     if(this.customization.model_parts[this.model_parts[i].render_slug]) {

                //         if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                //             request_photo+='&texture_'+this.model_parts[i].render_slug+"="+this.customization.model_parts[this.model_parts[i].render_slug].texture.id
                //         //params['texture_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug].texture.id

                //         }
                //         else {
                //             request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                //         //params['texture_'+this.model_parts[i].render_slug]=4
                //         }

                //     }
                //     else {
                //         request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                //         //params['texture_'+this.model_parts[i].render_slug]=4
                //     }


                // }
                // else {
                //     request_photo+='&texture_'+this.model_parts[i].render_slug+"=4"
                //     //params['texture_'+this.model_parts[i].render_slug]=4
                // }
                
                
                }
                else if (this.model_parts[i].render_type=="lense")
                {
                // if(this.customization.model_parts)
                // {
                //   params['tint_'+this.model_parts[i].render_slug]=this.customization.model_parts[this.model_parts[i].render_slug] ? this.customization.model_parts[this.model_parts[i].render_slug].tint.id : 5
                // }
                // else {
                //   params['tint_'+this.model_parts[i].render_slug]= 5
                // }
                    // ici tint
                    if(this.activeTint)
                    {
                        request_photo+='&tint_'+this.model_parts[i].render_slug+"="+this.activeTint
                    }
                    else {
                        request_photo+='&tint_'+this.model_parts[i].render_slug+"=4"
                    }
                    
                //params['tint_'+this.model_parts[i].render_slug]=this.activeTint ? this.activeTint : 4
                }


                
        
          }
            

           

            // let request 

            // let branch_texture_id=this.activeTexture

            // if(this.activeBranchTexture!=null) {
            //     branch_texture_id=this.activeBranchTexture
            // }

            // let branch_color_id=this.activeColor

            // if(this.activeBranchColor!=null) {
            //     branch_color_id=this.activeBranchColor
            // }
            
            // if (this.activeTint != null) {
            //     if (this.activeTextureStyle != null) {
                    
            //         request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint='+this.activeTint+'&texture_style='+this.activeTextureStyle
            //     }
            //     else {
            //         request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint='+this.activeTint
            //     }
            // }
            // else {
            //     if (this.activeTextureStyle != null) {
            //         request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint=5&texture_style='+this.activeTextureStyle
            //     }
            //     else {
            //         request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint=5'
            //     }
            // }


            let datas = await APIRequest.list_renders('photos-layers-new', request_photo)
            //let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
            //let render_infos_datas=render_infos
            //this.allPhotosLoaded=true

       
            this.oldPhotoDatas=this.photoDatas

            // let numtofs=5
            // for(let i=1;i<=numtofs;i++)
            // {
            //     console.log('BLELELELLELELE')
            //     for(let j=0;j<datas['scene'+i].length;j++)
            //     {
            //         let new_render_name=datas['scene'+i][j].render+'?'+Math.random()
            //         datas['scene'+i][j].render=new_render_name
            //         console.log('teeeest')
            //         console.log(new_render_name)
            //     }
                
            // }


            this.photoHoverDatas=datas
            this.photoDatas=datas


            if(datas.scene1)
            {
                for(let i=0;i<datas.scene1.length;i++)
                {
                    if(datas.scene1[i].render_engraving)
                    {
                
                        this.currentBranchPhotoPresets=datas.scene1[i].presets
                        this.currentBranchPhoto=datas.scene1[i].render_engraving
                    }
                   
                }
                
            }

            if(this.currentBranchPhoto==null)
            {
                await this.calculateBranchPhoto()
            }
           

  
        },
        hideOnMesurePopup() {
            this.displayOnMesurePopup=false
            jQuery('.scrollmagic-pin-spacer').css('z-index','auto')
        },
        hideGlassTypePopup() {
            this.showGlassTypePopup=false
            
        },
        hideGlassOptionPopup() {
            this.showGlassOptionPopup=false
            
        },
        hideColorPopup() {
            this.showColorPopup=false
            
        },
        hideTexturePopup() {
            this.showTexturePopup=false
            
        },
        setDisplayFiveSlides(value) {
            if(value!=this.fiveSlides) {
                this.fiveSlides=value
                if (this.slider != null) {
                    let info = this.slider.getInfo();                
                    if(value==false && info.index==4) {
                       this.currentIndex = 3; 
                       this.slider.goTo(3)
                    }
                    else {
                        this.currentIndex = info.index;
                    }
                } 
            }     
        },
        displayHowItWorkPopupToFalse() {
            this.displayHowItWorkPopup = false
            jQuery('.scrollmagic-pin-spacer').css('z-index','auto')
        },
        async getRecommendedSize() {
            this.loadingRecommendedSize=true
            if (this.activeScan != null) {
                let size = await APIRequest.get_scan_sizes(this.activeScan, this.glass.id)
                let size_regular = size[0].regular

                if (size_regular <= 46) {
                    this.recommandedSize = "XS"
                    this.activeSize = "xs"
                }
                if (size_regular <= 50) {
                    this.recommandedSize = "S"
                    this.activeSize = "s"
                }
                if (size_regular <= 52) {
                    this.recommandedSize = "M"
                    this.activeSize = "m"
                }
                else {
                    this.recommandedSize = "L"
                    this.activeSize = "l"
                }
            }
            this.loadingRecommendedSize=false
        },
        async displaySharePopup() {



            this.displayLoadingShare = true
            let options = {}
            options.branch = {}
            options.glass = {}
            options.glass.id = this.glass.id
            options.amount = this.current_price
            options.branch.id = this.activeBranch
            options.color = {}
            options.color.id = this.activeColor
           
            //options.branch_color = {id:this.activeBranchColor}
            if (this.activeTint != null) {
                options.tint = {}
                options.tint.id = this.activeTint
            }
            else {
                options.tint = null
            }
            if (this.activeGlassType && this.activeGlassType != "nocorrection") {
                options.glass_type = {}
                options.glass_type.id = this.activeGlassType
            }
            else {
                options.glass_type = null
            }
            if (this.activeGlassOption) {
                options.glass_option = {}
                options.glass_option.id = this.activeGlassOption
            }
            else {
                options.glass_option = null
            }
            options.engraving_text = this.gravure
            if (this.activeScan != null) {
                options.scan = {}
                options.scan.id = this.activeScan
                options.size = null
            }
            else {
                options.scan = null
                options.size = this.activeSize
            }
            if (this.gravure != null && this.gravure != "") {
                options.engraving_text = this.gravure
            }
            else {
                options.engraving_text = ""
            }
            

            options.order_item_customizations=this.calculateOrderItemCustom()
            this.favoriteShare = await APIRequest.create('favorites', options)
            this.displayShare = true
            this.displayLoadingShare = false
        },
        calculateOrderItemCustom()
        {
            let order_item_customizations=[]
            for(let i=0;i<this.model_parts.length;i++)
            {
                if(this.model_parts[i].type=="color" || this.model_parts[i].type=="texture_color" || this.model_parts[i].render_type=="lense") {

                
                let item = {}
                item.model_part={id:this.model_parts[i].id}
                if(this.model_parts[i].type=="color")
                {
                    if(this.customization.model_parts)
                    {
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                        {
                            item.color={id:this.customization.model_parts[this.model_parts[i].render_slug].color.id}
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                            {
                            item.color={id:6}
                            }
                            else {
                            item.color={id:19}
                            }
                        }
                    }
                    else {
                        if(this.model_parts[i].material=="plastic")
                        {
                        item.color={id:6}
                        }
                        else {
                        item.color={id:19}
                        }
                        
                    }
                    
                    }
                    else {
                    if(this.model_parts[i].material=="plastic")
                    {
                        item.color={id:6}
                    }
                    else {
                        item.color={id:19}
                    }
                    }
                }
                if(this.model_parts[i].type=="texture_color")
                {
                
                    if(this.customization.model_parts)
                    {
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                    
                        if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                
                        item.texture_style={id:this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id}
                        }

                        if(this.customization.model_parts[this.model_parts[i].render_slug].texture)
                        {
                            item.texture={id:this.customization.model_parts[this.model_parts[i].render_slug].texture.id}
                        
                        }
                        else {
                            item.texture={id:4}
                        }
                        
                    
                        
                        if(this.customization.model_parts[this.model_parts[i].render_slug].color)
                        {
                            item.color={id:this.customization.model_parts[this.model_parts[i].render_slug].color.id}
                        }
                        else {
                            if(this.model_parts[i].material=="plastic")
                        {
                        item.color={id:6}
                        }
                        else {
                        item.color={id:19}
                        }
                        }
                        

                    
                    }
                    else {
                        if(this.model_parts[i].material=="plastic")
                        {
                        item.color={id:6}
                        }
                        else {
                        item.color={id:19}
                        }

                        item.texture={id:4}
                        
                    }
                    
                    }
                    else {
                    
                    if(this.model_parts[i].material=="plastic")
                    {
                        item.color={id:6}
                    }
                    else {
                        item.color={id:19}
                    }
                    item.texture={id:4}
                    }
                }
                if(this.model_parts[i].render_type=="lense")
                {
                    item.tint={id:this.activeTint ? this.activeTint : 5}
                }

                order_item_customizations.push(item)
                }
            }
            return order_item_customizations;
        },
        async saveGlass() {
            if (!localStorage.id_user_senseye_auth) {
                this.displayLogin(true)
            }
            else {
                if (this.saved == true) {
                    await this.removeFavorite()
                }
                else {
                    this.displayLoadingShare = true
                    let options = {}
                    options.branch = {}
                    options.glass = {}
                    options.glass.id = this.glass.id
                    options.amount = this.current_price
                    options.branch.id = this.activeBranch
                    options.color = {}
                    options.color.id = this.activeColor
                   
                 
                    //options.branch_color = {id:this.activeBranchColor}
                    if (localStorage.id_user_senseye_auth) {
                        options.user = {}
                        options.user.id = localStorage.id_user_senseye_auth
                    }
                    if (this.activeTint != null) {
                        options.tint = {}
                        options.tint.id = this.activeTint
                    }
                    else {
                        options.tint = null
                    }

                    if (this.activeGlassType && this.activeGlassType != "nocorrection") {
                        options.glass_type = {}
                        options.glass_type.id = this.activeGlassType
                    }
                    else {
                        options.glass_type = null
                    }
                    if (this.activeGlassOption) {
                        options.glass_option = {}
                        options.glass_option.id = this.activeGlassOption
                    }
                    else {
                        options.glass_option = null
                    }
                    options.engraving_text = this.gravure

                    if (this.activeScan != null) {
                        options.scan = {}
                        options.scan.id = this.activeScan
                        options.size = null
                    }
                    else {
                        options.scan = null
                        options.size = this.activeSize
                    }
                    options.order_item_customizations=this.calculateOrderItemCustom()
                    let favorite = await APIRequest.create('favorites', options)
                    this.favoriteId = favorite.id
                    this.saved = true
                    this.displayLoadingShare = false
                    this.save=true
                    this.showModal('Le produit a bien été enregistré !')
                }
            }
        },
        async hoverElement(type,index_model_part,id) {
            
        },
        async hoverColor(index_model_part,color) {
            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }

            // let branch_texture_id=this.activeTexture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }

            // let branch_color_id=color
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }
            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }
            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+color+'&texture='+current_texture+'&branch='+this.activeBranch+'&tint='+current_tint
            // if(this.activeTextureStyle!=null) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && current_texture!=4) {
            //     request+='&texture_style=1'
            // }
            // console.log('ICI HOVER')
            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]


            // this.hoverPhotoDisplay=true
        

            
        },
        async hoverBranchColor(color) {
            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }

            // let branch_texture_id=this.activeTexture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }
            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }
            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+color+'&color='+this.activeColor+'&texture='+current_texture+'&branch='+this.activeBranch+'&tint='+current_tint
            // if(this.activeTextureStyle!=null) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && current_texture!=4) {
            //     request+='&texture_style=1'
            // }

            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]


            // this.hoverPhotoDisplay=true
        

            
        },
        async hoverTexture(index_model_part,texture) {
            

          

            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            
           
            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }

            // let branch_color_id=this.activeColor
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }

            // let branch_texture_id=texture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }

            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+texture+'&branch='+this.activeBranch+'&tint='+current_tint
            
            // if(this.activeTextureStyle!=null && texture!=4) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && texture!=4) {
            //     request+='&texture_style=1'
            // }

           
            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]

        

            // this.hoverPhotoDisplay=true
        },
        async hoverBranchTexture(texture) {
            

          

            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }

           
            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }

           

            // let branch_color_id=this.activeColor
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }

            // request='?glass='+this.glass.id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&branch_texture='+texture+'&texture='+current_texture+'&branch='+this.activeBranch+'&tint='+current_tint
            
            // if(this.activeTextureStyle!=null && texture!=4) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && texture!=4) {
            //     request+='&texture_style=1'
            // }

           
            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]

    

            // this.hoverPhotoDisplay=true
        },
        async hoverTint(index_model_part,tint) {
            
         
            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1
            
            // let branch_color_id=this.activeColor
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }

            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }

    
            // let branch_texture_id=this.activeTexture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }

            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+current_texture+'&branch='+this.activeBranch+'&tint='+tint
          

            // if(this.activeTextureStyle!=null) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && current_texture!=4) {
            //     request+='&texture_style=1'
            // }

            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]

            
            // this.hoverPhotoDisplay=true

        },
        async hoverTextureStyle(index_model_part,texture_style) {
        
        
            // this.photoHoverDatas=null
            

            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }
            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }
            // let branch_color_id=this.activeColor
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }
            // let branch_texture_id=this.activeTexture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }
        
           
            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+current_texture+'&branch='+this.activeBranch+'&tint='+current_tint+'&texture_style='+texture_style


            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]

        

            // this.hoverPhotoDisplay=true
        
        
        
        },
        async hoverBranch(branch) {
    
    
            // let info = this.slider.getInfo();
            // this.orderPhotoHover=info.index

            // let request 

            // let scene=this.orderPhotoHover+1

            // let current_texture=4
            // if(this.activeTexture!=null)
            // {
            //     current_texture=this.activeTexture
            // }
            // let current_tint=5
            // if(this.activeTint!=null)
            // {
            //     current_tint=this.activeTint
            // }

            // let branch_color_id=this.activeColor
            // if(this.activeBranchColor!=null)
            // {
            //     branch_color_id=this.activeBranchColor
            // }

            // let branch_texture_id=this.activeTexture
            // if(this.activeBranchTexture!=null)
            // {
            //     branch_texture_id=this.activeBranchTexture
            // }

           
            // request='?glass='+this.glass.id+'&branch_texture='+branch_texture_id+'&branch_color='+branch_color_id+'&color='+this.activeColor+'&texture='+current_texture+'&branch='+branch+'&tint='+current_tint
           

            // if(this.activeTextureStyle!=null) {
            //     request+='&texture_style='+this.activeTextureStyle
            // }
            // else if(this.activeTextureStyle==null && current_texture!=4) {
            //     request+='&texture_style=1'
            // }

            // let datas = await APIRequest.list_renders('photos-layers', request)
            // this.photoHoverDatas=datas['scene'+scene]

         

            // this.hoverPhotoDisplay=true

        },
        hideHoverPhoto() {
            this.hoverPhotoDisplay=false
        },
        async removeFavorite() {
            this.loaded = false
            await APIRequest.delete('favorites', this.favoriteId)
            this.saved = false
            this.loaded = true
        },
        selectTintCategory(id, index) {
            this.activeTintCategory = id
            this.actualTints = this.tint_categories[index].tints
        },
        selectBranchCategory(id, index) {
            this.activeBranchCategory = id
        },
        gravureFn(gravure) {
            this.gravure = gravure;
        },
        photosEvent(photos) {
            this.photos = photos;
        },
        displayLogin(saving) {
            if (saving == true) {
                
                this.$emit('displayPopupLoginEv', {review:false,saving:true,customing:false,liking:false,likingId:null})
            
               // emitter.emit('display-popup-login', false, true)
            }
            else {

                this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:true,liking:false,likingId:null})
               
                //emitter.emit('display-popup-login', false, false, true)
            }
        },
        async reloadTints(){
            this.loadingTints=true
            this.activeTints=null
            if(this.activeTintCategory=='all') {
                this.activeTints=this.tints
            }
            else {
                if(this.activeGlassOption==null)
                {
                
                }
                else
                {
                    let tints=[]
                    for(let j=0;j<this.tints.length;j++) {
                        let glass_options= await APIRequest.detail('tints',this.tints[j].id)
                        let activeGlassOptions=glass_options.glass_options;
                        
                        for(let i=0;i<activeGlassOptions.length;i++)
                        {
                            if(activeGlassOptions[i].id==this.activeGlassOption)
                            {
                                tints.push(this.tints[j])
                            }
                        }
                    }
                    this.activeTints=tints
                }
            }
           
            this.loadingTints=false
        },
        async selectGlassOption(glass_option, name, index, price) {
            this.activeGlassOption = glass_option
            this.activeGlassOptionName = name
            this.activeGlassOptionPrice = price
            this.activeGlassOptionIndex = index

          
            if (glass_option == null) {
                // on select le verre transparent
                this.displayTintChoice = false
                
                if(this.tints)
                {
                    if(this.glass)
                    {
                        if(this.glass.optic==true)
                        {
                            await this.selectTint(5,0);
                        }
                        else
                        {
                            await this.selectTint(this.tints[0].id,this.tints[0].price)
                        }
                    }
                    else
                    {
                        await this.selectTint(this.tints[0].id,this.tints[0].price)
                    }
                    
                }
                else
                {
                    await this.selectTint(5,0);
                }
                
                if (this.activePersonnalisation == "tint") {
                    this.selectPersonnalisation('color')
                }
            }
            else {
                // on verifie si on n'affiche que les verres transparents ou non
                if (this.glass_options[index].transparent_only == true) {
                    this.displayTintChoice = false
                    console.log('transparent only')
                    
                    await this.selectTint(5,0);
                    if (this.activePersonnalisation == "tint") {
                        this.selectPersonnalisation('color')
                    }
                }
                else {
                    this.displayTintChoice = true
                    await this.reloadTints()
                    // on affiche l'onglet verres
                    //this.selectPersonnalisation('tint')
                    // if(this.activeTint==null) {
                    //     this.selectTint(4,0);
                    // }
//iciciic
                if(!this.$route.params.favorite)
                {
                    await this.selectTint(4,0);
                }
                   
                }
            }
            this.selectPersonnalisation(this.activePersonnalisation)
           // this.displayGlassOptions = false
        },
        displayHowItWork() {
            if (this.activeToggle == "size") {
                jQuery('.scrollmagic-pin-spacer').css('z-index',"-1")
                this.displayHowItWorkPopup = true
            }
            else {
                jQuery('.scrollmagic-pin-spacer').css('z-index',"-1")
                this.displayOnMesurePopup = true
            }
        },
        displayGlassTypePopup() {
            this.showGlassTypePopup = true
        },
        displayGlassOptionPopup() {
            this.showGlassOptionPopup = true
        },
        displayColorPopup() {
            this.showColorPopup = true
        },
        displayTexturePopup() {
            this.showTexturePopup = true
        },
        displayAppPopup() {
            emitter.emit('display-popup-app');
        },
        async selectScan(id_scan, scan) {
            if(scan==null)
            {
                if(this.language=='fr')
                {
                    this.selectedScan = "+ Nouveau scan"
                }
                else
                {
                    this.selectedScan = "+ New scan"
                }
                this.activeSize="onmeasure"
            }
            else 
            {
                this.selectedScan = scan
            }
            
            this.activeScan = id_scan
            this.displayScans = false
        },
        async getGlassTypes() {
            this.loadingGlassTypes=true
            let glass_types = await APIRequest.list('glass-types')

    
            if(this.useSettings.displayOptic==true) {
               this.glass_types = glass_types[0];
            }
            else {
                
                 let glass_types_array_final=[]
                let glass_types_array=glass_types[0]
                for(let i=0;i<glass_types_array.length;i++) {
                    if(glass_types_array[i].id==5) {
                        glass_types_array_final.push(glass_types_array[i])
                    }
                }
                this.glass_types=glass_types_array_final
            }
            
            this.loadingGlassTypes=false
            this.selectGlassType('nocorrection','Sans correction',0)
        },
        async getGlassOptions() {
            this.loadingGlassOptions=true
          
            let request = "?glass=" + this.glassId
            let glass_options= await APIRequest.list_with_request('glass-options',request)
        
            this.glass_options=[]
            for(let i=0;i<glass_options[0].length;i++)
            {
                let item = await APIRequest.detail('glass-options',glass_options[0][i].id)
                this.glass_options.push(item)
            }
            this.loadingGlassOptions=false
        },
        async getUserScans() {
            this.loadingScans=true
            if (localStorage.id_user_senseye_auth) {
                let userScans = await APIRequest.list('scans', localStorage.id_user_senseye_auth,null,20)
                this.userScans = userScans[0];
            }
            this.loadingScans=false
        },
        selectGlassType(type, name, price) {
            this.activeGlassType = type
            this.activeGlassTypeName = name
            this.activeGlassTypePrice = price
            
            if(this.activeGlassOptionIndex!=null) {
                if(this.glass_options[this.activeGlassOptionIndex].id==5) {

                }
            }
            else {
                if(this.tints)
                {
                    this.activeTint = this.tints[0].id
                }
                else
                {
                    this.activeTint = null
                }
                
                this.activeTintPrice = 0
                this.selectGlassOption(null, 'Option de verre', 0, 0);
            }
            
        },
        selectPersonnalisation(personnalisation) {
            this.displayTextPreview = false
            this.photo = this.currentPhoto
           
            if (personnalisation == 'style') {
                this.underlinePosition = 0;
            }
            if (personnalisation == 'color') {
                this.underlinePosition = 0;
            }
            else if (personnalisation == 'texture') {
                if(this.fiveSlides==true) {
                    this.slider.goTo(3)
                }
                else {
                    this.slider.goTo(1)
                }
                if(this.displayTintChoice==true) {
                    this.underlinePosition = 25;
                }
                else {
                    this.underlinePosition = 25;
                }
                
            }
            else if (personnalisation == 'branch') {

                if(this.fiveSlides==true) {
                    this.slider.goTo(3)
                }
                else {
                    this.slider.goTo(1)
                }
               
                 if(this.displayTintChoice==true) {
                    this.underlinePosition = 50;
                }
                else {
                    this.underlinePosition = 50;
                }
            }
            else if (personnalisation == 'tint') {
               
                 if(this.displayTintChoice==true) {
                    this.underlinePosition = 75;
                }
                else {
                    this.underlinePosition = 75;
                }
            }
            else if (personnalisation == 'gravure') {
                this.displayTextPreview = true
                if (this.displayTintChoice == true) {
                    this.underlinePosition = 100;
                }
                else {
                    this.underlinePosition = 75;
                }
                for (let i = 0; i < this.glass.branchs.length; i++) {
                    if (this.activeBranch == this.glass.branchs[i].id) {
                        this.photo = this.glass.branchs[i].photo
                    }
                }
            }
            this.activePersonnalisation = personnalisation
        },
        selectStyle(style) {
            this.activeStyle = style
        },
        selectBranchColor(color, price, index) {
            this.independantBranch=true
            this.activeBranchColor = color
        },

       
        
        selectColor(index_model_part,color, price, index) {

            if((this.allPhotosLoaded  && !this.favorite) || this.favorite!=null)
            {

                if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
                {
                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].color)
                    {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug].color.id=color
                    }
                    else {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug].color={id:color}
                    }
                }
                else {
                    this.customization.model_parts[this.model_parts[index_model_part].render_slug]={color:{id:color}}
                }
                

                this.activeColor = color
                
                if(!this.independantBranch) {
                    this.activeBranchColor = color
                }
                this.activeColorPrice = price

                if(this.associateTemples)
                {
                    for(let i=0;i<this.model_parts.length;i++)
                    {
                        if(this.model_parts[i].render_slug=='temples' && this.model_parts[i].material=='plastic')
                        {
                            if(this.customization.model_parts['temples'])
                    {
                        if(this.customization.model_parts['temples'].color)
                        {
                            this.customization.model_parts['temples'].color.id=color
                        }
                        else {
                            this.customization.model_parts['temples'].color={id:color}
                        }
                    }
                    else {
                        this.customization.model_parts['temples']={color:{id:color}}
                    }
                        }
                    }
                
                }
            }
            // this.activeBranch = this.model_parts[index_model_part].colors[index].branches[0].id
            // this.activeBranchPrice = this.model_parts[index_model_part].colors[index].branches[0].price
        },
        selectBranch(branch, price) {
            if((this.allPhotosLoaded  && !this.favorite ) || this.favorite!=null)
            {
                this.activeBranch = branch
                this.activeBranchPrice = price
            }
           
        },
        selectTint(tint, price) {
            console.log('select tint')
            console.log(tint)
            if(this.allPhotosLoaded && !this.favorite)
            {
                this.activeTint = tint
                this.activeTintPrice = price
            }
            else if(this.favorite!=null)
            {
                this.activeTint = tint
                this.activeTintPrice = price
            }
        },
        selectTextureStyle(texture_style, price) {
            if((this.allPhotosLoaded  && !this.favorite) || this.favorite!=null)
            {
                this.activeTextureStyle = texture_style
                this.activeTextureStylePrice = price

                if(this.customization.model_parts['frame'])
                {
                    if(this.customization.model_parts['frame'].texture_style)
                    {
                        this.customization.model_parts['frame'].texture_style.id=texture_style
                    }
                    else {
                        this.customization.model_parts['frame'].texture_style={id:texture_style}
                    }
                }
                else {
                    this.customization.model_parts['frame']={texture_style:{id:texture_style}}
                }

            }

        },
        selectTexture(index_model_part,texture, price, index) {
            if((this.allPhotosLoaded  && !this.favorite) || this.favorite!=null)
            {
                if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
                {
                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture)
                    {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture.id=texture
                    }
                    else {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture={id:texture}
                    }
                }
                else {
                    this.customization.model_parts[this.model_parts[index_model_part].render_slug]={texture:{id:texture}}
                }

                if(texture!=4){

                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style)
                        {
                            this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style.id=1
                        }
                        else {
                            this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style={id:1}
                        }
                    }
                    else {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug]={texture_style:{id:1}}
                    }

                    this.activeTextureStyle=1
                

                }
                else {
                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style)
                        {
                            this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style.id=0
                        }
                        else {
                            this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style={id:0}
                        }
                    }
                    else {
                        this.customization.model_parts[this.model_parts[index_model_part].render_slug]={texture_style:{id:0}}
                    }

                }



                this.activeTexture = texture
                
                this.activeTexturePrice = price
                this.activeTextureObject = this.glass.textures[index]

                if(this.associateTemples)
                {
                    for(let i=0;i<this.model_parts.length;i++)
                    {
                        if(this.model_parts[i].render_slug=='temples' && this.model_parts[i].material=='plastic')
                        {

                            if(this.customization.model_parts['temples'])
                            {
                                if(this.customization.model_parts['temples'].texture)
                                {
                                    this.customization.model_parts['temples'].texture.id=texture
                                }
                                else {
                                    this.customization.model_parts['temples'].texture={id:texture}
                                }
                            }
                            else {
                                this.customization.model_parts['temples']={texture:{id:texture}}
                            }
                        }
                    }
                }
                // if(!this.independantBranch) {
                //     this.activeBranchTexture = texture
                // }
                // if (this.activeTextureObject.texture_styles.length > 0) {
                //     this.activeTextureStyle = this.activeTextureObject.texture_styles[0].id
                //     this.activeTextureStylePrice = this.activeTextureObject.texture_styles[0].price
                // }
                // else {
                //     this.activeTextureStyle = null
                //     this.activeTextureStylePrice = 0
                // }

                this.branchTextureEnabled=true
            }
           
        },
        selectBranchTexture(texture, price, index) {
            if((this.allPhotosLoaded  && !this.favorite) || this.favorite!=null)
            {
                this.independantBranch=true
                this.activeBranchTexture = texture
            }
        },
        selectSize(size) {
            this.activeSize = size
            this.activeSizePopup = size
            this.activeScan = null
        },
        selectSizePopup(size) {
            this.activeSizePopup = size
        },
        toggleSelect(toggle) {
            if (toggle == 'size') {
                this.activeSize = "onesize"
                this.togglePosition = 50;
                this.activeScan = null
                this.activeToggle = toggle
            }
            else {
                if(this.glass.custom_fit==true)
                {

                    if (!localStorage.id_user_senseye_auth) {
                        if(this.activeSize!='onmeasure')
                        {
                            this.displayLogin(false)
                        }
                        else {
                            this.activeToggle = toggle
                            this.togglePosition = 0;
                        }
                        
                    }
                    else if (this.userScans == null || this.userScans.length == 0) {
                        if(this.activeSize!='onmeasure')
                        {
                            this.$emit('displayPopupLoginEv', {review:false,saving:false,customing:true,liking:false,likingId:null})
                            //emitter.emit('display-popup-login')
                        }
                        else {
                            this.activeToggle = toggle
                            this.togglePosition = 0;
                        }
                        
                    }
                    // si pas de scans, on affiche le no scan
                    else {

                    
                        this.activeSize = "onmeasure"
                        this.activeToggle = toggle
                        this.togglePosition = 0;
                    }
                }
                else
                {
                    // ICI
                    if(this.language=='fr')
                    {
                        this.showModal("Le sur-mesure n'est pas encore disponible pour ce modèle. Inscrivez-vous à notre newsletter pour être informé des mises à jour.")
                    }
                    else 
                    {
                        this.showModal("The custom-fit is not yet available for this model. Sign up to our newsletter to be notified when upgrades are added.")
                    }
                    
                }
            }
        },
        closeModal($event) {
            if (jQuery($event.target).parents('.content').length) {

            }
            else {
                this.displayModal = false
            }
        },
        async getFavorite() {
            this.favorite = await APIRequest.detail('favorites', this.$route.params.favorite)

            
            

            if (this.favorite.glass_type != null) {
                this.selectGlassType(this.favorite.glass_type.id, this.favorite.glass_type.name, this.favorite.glass_type.price)
            }
           

            if(this.community==true)
            {
                await this.selectGlassOption(4, "Verres solaires", 1, 20)
            }
            else {
                if (this.favorite.glass_option != null) {



                    console.log('glass optionnnn')
                    console.log(this.favorite.glass_option)

                    for (let i = 0; i < this.glass_options.length; i++) {
                        if (this.glass_options[i].id == this.favorite.glass_option.id) {
                            this.selectGlassOption(this.favorite.glass_option.id, this.favorite.glass_option.name, i, this.favorite.glass_option.price)
                        }
                    }
                }
            }

            if (this.favorite.tint != null) {

               
                this.selectTint(this.favorite.tint.id, this.favorite.tint.price)
            }

            if (this.favorite.order_item_customizations != null) {

                for (let i = 0; i < this.favorite.order_item_customizations.length; i++) {
                    for(let j=0;j<this.model_parts.length;j++)
                    {

                        if(this.model_parts[j].id==this.favorite.order_item_customizations[i].model_part.id)
                        {
                            console.log("on a un model part ")
                            if(this.favorite.order_item_customizations[i].color)
                            {
                                console.log("color ok")
                                this.selectColor(j,this.favorite.order_item_customizations[i].color.id, this.favorite.order_item_customizations[i].color.price,0)
                            }
                            if(this.favorite.order_item_customizations[i].texture)
                            {
                                console.log("texture ok")
                                this.selectTexture(j,this.favorite.order_item_customizations[i].texture.id, this.favorite.order_item_customizations[i].texture.price,0)
                            }
                            if(this.favorite.order_item_customizations[i].texture_style)
                            {
                                this.selectTextureStyle(this.favorite.order_item_customizations[i].texture_style.id, this.favorite.order_item_customizations[i].texture_style.price)
                            }
                            if(this.favorite.order_item_customizations[i].tint && !this.favorite.tint)
                            {
                                console.log("tint ok")
                                this.selectTint(this.favorite.order_item_customizations[i].tint.id, this.favorite.order_item_customizations[i].tint.price)
                            }
                           
                        }
                    }

                }
            }
            
           
            if (this.favorite.scan != null) {
                this.toggleSelect('custom');
                this.selectedScan = this.favorite.scan.name
                this.activeScan = this.favorite.scan.id
            }
            else {
                this.selectSize(this.favorite.size)
            }

            
           
            if (this.favorite.branch != null) {
                this.selectBranch(this.favorite.branch.id, this.favorite.branch.price)
            }
            if (this.favorite.engraving_text != null) {
                this.gravure = this.favorite.engraving_text
            }

            this.photosLoaded = true
            this.loadingPhotos=false
            //this.allPhotosLoaded=true
          
            
        },
        async getGlass() {
            this.loadingGlass=true
            
            this.glass = await APIRequest.detail('glasses', this.glassId)
            

        },
        async getBranches(){
            this.loadingBranches=true
            let request = "?glass=" + this.glassId
            let branches = await APIRequest.list_with_request('branches', request)
            this.branchs=branches[0]
            this.activeBranch = branches[0][0].id
            this.activeBranchPrice = branches[0][0].price
            this.loadingBranches=false
        },
        initDefaultValues() {
            // for(let i=0;i<this.model_parts.length;i++){
            //     if(this.model_parts[i].type=='texture_color' )
            // }
        },
        async getModelParts(){
            let request_modelparts = '?glass='+this.glassId
            let model_parts =  await APIRequest.list_with_request('model-parts',request_modelparts)
            this.model_parts=model_parts[0]

            for(let i=0;i<this.model_parts.length;i++)
            {
                if((this.model_parts[i].type=='color' || this.model_parts[i].type=='texture_color') && this.model_parts[i].render_slug=='temples')
                {
                    this.branchMaterial=this.model_parts[i].material
                   
                    if(this.model_parts[i].colors.length>0)
                    {
                
                        this.hasBranch=true
                    }

                    
                }
                else if((this.model_parts[i].type=='color' || this.model_parts[i].type=='texture_color') && this.model_parts[i].render_slug=='frame'){
                    if(this.model_parts[i].textures.length>0)
                    {
                
                        this.hasTexture=true
                    }
                }
            }

            this.initDefaultValues()

            
        },
        async resetColors()

        {
            for(let i=0;i<this.model_parts.length;i++)
            {
                if((this.model_parts[i].type=="texture" || this.model_parts[i].type=="texture_color") && (this.model_parts[i].render_slug=="frame1" || this.model_parts[i].render_slug=="frame"))
                {
                    this.customization.model_parts[this.model_parts[i].render_slug].texture_style={id:0}
                    this.customization.model_parts[this.model_parts[i].render_slug].texture={id:4}
                }
            }

            //this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture_style={id:0}
            await this.selectTint(5,0);
            //this.activeTextureStyle=0
            await this.getTextures()
            await this.getColors()
            await this.getTintCategories()
            
        },
        async getColors(){

            // ici default
            this.loadingColors=true
           
            for(let i=0;i<this.model_parts.length;i++)
            {   
                if((this.model_parts[i].type=="color" || this.model_parts[i].type=="texture_color") && (this.model_parts[i].render_slug=="frame1" || this.model_parts[i].render_slug=="frame"))
                {
                   
                    
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].color) {
                           
                            this.customization.model_parts[this.model_parts[i].render_slug].color.id = this.model_parts[i].colors[0].id
                        }
                        else {
                          
                            this.customization.model_parts[this.model_parts[i].render_slug].color={id:this.model_parts[i].colors[0].id}
                        }
                        
                    }
                    else {
                        
                        this.customization.model_parts[this.model_parts[i].render_slug]={color:{id:this.model_parts[i].colors[0].id}}
                    }
                    this.activeColor = this.model_parts[i].colors[0].id
                    this.activeColorPrice = this.model_parts[i].colors[0].price
             
                }
                else if((this.model_parts[i].type=="color" || this.model_parts[i].type=="texture_color") && this.model_parts[i].render_slug=="temples")
                {



                    let frameColor

                    if(this.model_parts[i].material=="plastic")
                    {
                        for(let p=0;p<this.model_parts.length;p++)
                        {   
                            if((this.model_parts[p].type=="color" || this.model_parts[p].type=="texture_color") && (this.model_parts[p].render_slug=="frame1" || this.model_parts[p].render_slug=="frame"))
                            {
                                frameColor=this.model_parts[p].colors[0].id
                            }
                        }
                    }
                    
                       
                    
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].color) {

                            if(this.model_parts[i].colors.length>0)
                            {
                                this.customization.model_parts[this.model_parts[i].render_slug].color.id = this.model_parts[i].colors[0].id
                            }
                            else
                            {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    this.customization.model_parts[this.model_parts[i].render_slug].color.id = frameColor
                                }
                                else {

                                    this.customization.model_parts[this.model_parts[i].render_slug].color.id = 19
                                 
                                }
                                
                            }
                            

                            
                        }
                        else {

                            if(this.model_parts[i].colors.length>0)
                            {
                                this.customization.model_parts[this.model_parts[i].render_slug].color={id:this.model_parts[i].colors[0].id}
                            }
                            else
                            {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    this.customization.model_parts[this.model_parts[i].render_slug].color = {id:frameColor}
                                }
                                else {

                                    this.customization.model_parts[this.model_parts[i].render_slug].color = {id:19}
                                 
                                }
                                
                            }
                           
                        }
                        
                    }
                    else {

                        if(this.model_parts[i].colors.length>0)
                            {
                                this.customization.model_parts[this.model_parts[i].render_slug]={color:{id:this.model_parts[i].colors[0].id}}
                            }
                            else
                            {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    this.customization.model_parts[this.model_parts[i].render_slug] = {color:{id:frameColor}}
                                }
                                else {

                                    this.customization.model_parts[this.model_parts[i].render_slug] = {color:{id:19}}
                                 
                                }
                                
                            }
                        
                       
                    }

                    if(this.model_parts[i].colors.length>0)
                            {
                                this.activeBranchColor = this.model_parts[i].colors[0].id
                            }
                            else
                            {
                                if(this.model_parts[i].material=="plastic")
                                {
                                    this.activeBranchColor = frameColor
                                }
                                else {

                                    this.activeBranchColor = 19
                                 
                                }
                                
                            }
                    
                    
                    
                    
                }
                else if((this.model_parts[i].type=="texture" || this.model_parts[i].type=="texture_color"))
                {

                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.model_parts[i].textures.length)
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture.id = this.model_parts[i].textures[0].id
                            }
                            else {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture={id:this.model_parts[i].textures[0].id}
                            }

                            if(this.model_parts[i].textures[0].texture_styles.length)
                            {
                                if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                                    this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id = this.model_parts[i].textures[0].texture_styles[0].id
                                }
                                else {
                                    this.customization.model_parts[this.model_parts[i].render_slug].texture_style={id:this.model_parts[i].textures[0].texture_styles[0].id}
                                }
                            }
                        }
                        
                        
                    }
                    else {
                        if(this.model_parts[i].textures.length)
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture) {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture.id = this.model_parts[i].textures[0].id
                            }
                            else {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture={id:this.model_parts[i].textures[0].id}
                            }

                            if(this.model_parts[i].textures[0].texture_styles.length)
                            {
                                if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style) {
                                    this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id = this.model_parts[i].textures[0].texture_styles[0].id
                                }
                                else {
                                    this.customization.model_parts[this.model_parts[i].render_slug].texture_style={id:this.model_parts[i].textures[0].texture_styles[0].id}
                                }
                            }
                            
                        }
                    }
                    
                    this.activeBranchColor = this.model_parts[i].colors[0].id
                    
                    
                }
                
            }
            this.loadingColors=false

            
            
        },
        async getTextures(){

        

            this.loadingTextures=true
            for(let i=0;i<this.model_parts.length;i++)
            {
                if((this.model_parts[i].type=="texture" || this.model_parts[i].type=="texture_color") && this.model_parts[i].render_slug=="frame")
                {

             
                    
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].texture)
                        {
                            this.customization.model_parts[this.model_parts[i].render_slug].texture.id = this.model_parts[i].textures[0].id
                        }
                        else
                        {
                            this.customization.model_parts[this.model_parts[i].render_slug].texture={id : this.model_parts[i].textures[0].id}
                        }
                    }
                    else
                    {
                     
                        this.customization.model_parts[this.model_parts[i].render_slug]={texture:{id : this.model_parts[i].textures[0].id}}
                    }
                    this.activeTexture = this.model_parts[i].textures[0].id
                    this.activeTextureObject = this.model_parts[i].textures[0]
                    this.activeTexturePrice = this.model_parts[i].textures[0].price

                    if (this.model_parts[i].textures[0].texture_styles) {
                        
                        if (this.model_parts[i].textures[0].texture_styles.length > 0) {
                        
                        if(this.customization.model_parts[this.model_parts[i].render_slug])
                        {
                            if(this.customization.model_parts[this.model_parts[i].render_slug].texture_style)
                            {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture_style.id = 0
                            }
                            else
                            {
                                this.customization.model_parts[this.model_parts[i].render_slug].texture_style={id : 0}
                            }
                        }

                        else
                        {
                            this.customization.model_parts[this.model_parts[i].render_slug]={texture_style:{id : 0}}
                        }
                    
                    

                        //ICI
                        
                        this.activeTextureStyle = 0
                        this.activeTextureStylePrice = 0
                    }
                    }
             
                }
                else if((this.model_parts[i].type=="texture" || this.model_parts[i].type=="texture_color") && this.model_parts[i].render_slug=="temples")
                {
                    
                    this.activeBranchTexture=this.model_parts[i].textures[0].id
                    
                    
                    if(this.customization.model_parts[this.model_parts[i].render_slug])
                    {
                        if(this.customization.model_parts[this.model_parts[i].render_slug].texture)
                        {
                            this.customization.model_parts[this.model_parts[i].render_slug].texture.id = this.model_parts[i].textures[0].id
                        }
                        else
                        {
                            this.customization.model_parts[this.model_parts[i].render_slug].texture={id : this.model_parts[i].textures[0].id}
                        }
                    }

                    else
                    {
                        this.customization.model_parts[this.model_parts[i].render_slug]={texture_style:{id : this.model_parts[i].textures[0].id}}
                    }
                    // ICI
                    
                    
                }
                
            }
            this.loadingTextures=false


          
        },
        
       
        async getTintCategories() {
            this.loadingTintCategories=true
            let tint_categories = await APIRequest.list('tint-categories')
            let categories = tint_categories[0]
            this.tint_categories = []
        
            let indexcat = 0

            for(let j=0;j<this.model_parts.length;j++)
            {
                let model_part=this.model_parts[j]
          
                if(model_part.render_type=="lense")
                {
                   
                    this.tints=model_part.tints
                 
                }
            }

            // order tint by order_display
            this.tints.sort((a, b) => a.order_display - b.order_display)
       
            for (let i = 0; i < categories.length; i++) {
                let category=await APIRequest.detail('tint-categories',categories[i].id)

               
                let available_tints = category.tints.sort((a, b) => a.order_display - b.order_display)
                let cat_available = false
                // let request = "?glass=" + this.glassId
                // let glass_tints=await APIRequest.list_with_request('tints', request)
                // this.tints=glass_tints[0]
                for (let j = 0; j < available_tints.length; j++) {
                    for (let k = 0; k < this.tints.length; k++) {
                        if (available_tints[j].id == this.tints[k].id) {
                            if (cat_available == false) {
                                let item = categories[i]
                                item.tints = []
                                item.tints.push(this.tints[k])
                                this.tint_categories.push(item)
                                cat_available = true
                            }
                            else {
                              
                                this.tint_categories[indexcat].tints.push(this.tints[k])

                                
                            }
                        }
                    }
                    
                }
                if (cat_available == true) {
                    indexcat++
                }
            }
         
            this.loadingTintCategories=false
        },
        async getSizes() {
            this.loadingSizes=true
            let sizes = await APIRequest.list('sizes')
            this.sizes = sizes[0];
            this.loadingSizes=false

        },
        scrollToReviews() {
            jQuery('html, body').animate({
                scrollTop: jQuery("#reviews").offset().top
            }, 500);
        },
        changeNewsletterEmail(email) {
            this.email = email
        },
        chooseOption(option) {
            this.currentOption = option
            this.currentColor = 'color1'
        },
        displayModal($event) {
            this.currentImage = jQuery($event.target).attr('src');
            this.displayModal = true;
        },
        chooseColor(color) {
            this.currentColor = color
        },
        displayDevices() {
            if (this.showDevices == true) {
                this.showDevices = false
            }
            else {
                this.showDevices = true
            }
        },
        showModal(title) {
            this.loaded = true
            this.displayModal = true;
            this.title = title;
        },
        // async addToCart(product,$event)
        async addToCart(product) {

            console.log('add to cart')

            //this.loaded = false
            this.errors_addtocart={}
            if (this.activeGlassOptionName == 'Option de verre' && this.activeGlassTypeName == 'Type de verre') {
                // alert('Vous devez sélectionner un type de verre !')
                this.showModal('Vous devez sélectionner un type de verre !')
                this.save=false
                this.loaded = true
                this.errors_addtocart.glass_type='Vous devez sélectionner un type de verre !'
            }
            else if (this.activeGlassOptionName == 'Option de verre') {
                // alert('Vous devez sélectionner une option de verre !')
                if(this.language=='fr')
                {
                    this.showModal('Vous devez sélectionner une option de verre !')
                }
                else
                {
                    this.showModal('You must select a lens option!')
                }
                
                this.loaded = true
                this.save=false
                if(this.language=='fr')
                {
                    this.errors_addtocart.glass_option='Vous devez sélectionner un type de verre !'
                }
                else
                {
                    this.errors_addtocart.glass_option='You must select a lens option!'
                }
                
            }
            else {
                if (this.activeTint == null && this.displayTintChoice == true) {
                    this.selectPersonnalisation('tint')
                    // alert('Vous devez sélectionner une teinte de verre !')
                    if(this.language=='fr')
                    {

                        this.showModal('Vous devez sélectionner une teinte de verre !')
                    }
                    else
                    {
                        this.showModal('You must select a lens tint!')
                    }
                    this.save=false
                    this.loaded = true
                }
                else {
                    emitter.emit('display-cart-load');
                    let options = {}
                    options.branch = {}
                    options.branch.id = this.activeBranch
                    options.branch_price = this.activeBranchPrice
                    options.color = {}
                    options.color_price = this.activeColorPrice
                    options.color.id = this.activeColor
                   
                    options.texture_price = this.activeTexturePrice
                    options.tint = {}
                    options.tint.id = this.activeTint
                    options.tint_price = this.activeTintPrice
                   
                    if (this.activeGlassType && this.activeGlassType!="nocorrection") {
                        options.glass_type = {}
                        options.glass_type.id = this.activeGlassType
                        options.glass_type_price = this.activeGlassTypePrice
                    }
                    else {
                        options.glass_type = null
                        options.glass_type_price = 0
                    }
                    if (this.activeTextureStyle) {
                        options.texture_style = {}
                        options.texture_style.id = this.activeTextureStyle
                       // options.texture_price = this.activeTextureStylePrice
                    }
                    if (this.activeGlassOption) {
                        options.glass_option = {}
                        options.glass_option.id = this.activeGlassOption
                        options.glass_option_price = this.activeGlassOptionPrice
                    }
                    else {
                        options.glass_option = null
                        options.glass_option_price = 0
                    }
                    options.engraving_text = this.gravure
                    if (this.activeScan != null) {
                        options.scan = {}
                        options.scan.id = this.activeScan
                        options.size = null
                    }
                    else {
                        options.scan = null
                        options.size = this.activeSize
                    }

                    // ICI NEW 
                    // product_custom order_item
                    //options.style=this.activeStyle

                    

                    options.order_item_customizations=this.calculateOrderItemCustom()

                    window.dataLayer  = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'eec.addToCart',  		// name of an event. In this case, always stays as eec.addToCart
                        'ecommerce': {					// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
                            'currencyCode': 'EUR',			// the currency which is currently used by a visitor 
                            'add': {						// name of an action. In this case, always stays as add
                                'actionField': {			
                                    //'list': 'main'			// optional. name of a list from which the product was added to a cart  
                                },		
                                'products': [{     			// list of products added to a cart. Can contain multiple products at the same time
                                    'name': this.glass.name,      // name of a product that was added to a cart
                                    'id': this.glass.id,     	// id of a product
                                    'price': this.current_price.toFixed(2),		// price of a product
                                    'brand': 'Visages',		// brand/vendor of a product
                                    'category': 'Eyewear',	// category of a product
                                    //'variant': 'Silver',     // product variant. If there are no variants, exclude this key from the dataLayer.push
                                    'quantity': 1
                                }]
                            }
                        }
                    });

                    console.log('add to cart test')
                    console.log('price : '+this.current_price)
                    console.log(window.dataLayer)
                    

                    if (this.activeGlassType != 'nocorrection') {

                        this.$emit('addToCartCollectionEv', product, options, 'prescription')

                       // await emitter.emit('add-to-cart-collection', product, options, 'prescription');
                    }
                    else {

                        this.$emit('addToCartCollectionEv', product, options, 'noprescription')
                       //await emitter.emit('add-to-cart-collection', product, options, 'noprescription');

                    }
                    //this.loaded = false
                }
            }
        },
        async getReviews(){
            this.loadingReviews=true
            let reviews = await APIRequest.list('reviews', null, null, 100, 1, this.$route.params.id)
            this.reviews = reviews[0]

            let total_rating = 0
            for (let i = 0; i < this.reviews.length; i++) {
                total_rating += this.reviews[i].rate
            }
            if (this.reviews.length > 0) {
                this.average = total_rating / this.reviews.length
            }
            else {
                this.average=5
            }
            this.loadingReviews=false
        },
        initScene() {

            

            // HEEREEEE

            if(this.lastWindowY!=null) {
                if(window.innerHeight>this.lastWindowY)
                {
                    let diff=window.innerHeight-this.lastWindowY
                    document.getElementById('right-content-collectionglass').style.marginTop+=diff
                }
                else {
                    let diff=this.lastWindowY-window.innerHeight
                    document.getElementById('right-content-collectionglass').style.marginTop-=diff
                }
            }   
            
            if(window.innerWidth<=760) {

                // if(this.controller==null) {
                
                //     this.lastWindowY=window.innerHeight
                //     document.getElementById('right-content-collectionglass').style.marginTop='-'+window.innerHeight+'px'
                //     this.controller = new ScrollMagic.Controller();
                //     this.scene = new ScrollMagic.Scene({triggerElement: "#trigger1", duration: window.innerHeight,triggerHook: 0})
                //     .setPin("#left-content")
                //     //.addIndicators({name: "1 (duration: 300)"}) // add indicators (requires plugin)
                //     .addTo(this.controller);
                // }

                
               
            }
            // else {
            //         this.toTop=0
            //         if(this.controller!=null) {
            //             console.log('on destroy')
            //             this.scene.removePin(true)
            //             document.getElementById('right-content-collectionglass').style.marginTop=0
            //             // this.scene=null
            //             this.controller.destroy(true)
            //             this.controller=null
                    
            //         }

            //     }

         

        },
        checkEndSlider() {
            if(this.fiveSlides==false && this.slider.getInfo().index==4) {
                this.slider.goTo(3)
            }
        },
        dragStartSlider() {
          
            //jQuery('body').css('overflow-y','hidden');
           
        },
        dragEndSlider() {
          
            //jQuery('body').css('overflow-y','auto');
        },
        handleScroll() {
            if(window.innerWidth<=760) {

                this.toTop=window.scrollY
               
            }
            else {
                this.toTop=0
            }
            
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.initScene);
  },
  async created() {

        let productName=''
        let productDescription=''
        let productPrice='0'
        let brandName='Visages'
        this.glassId=this.$route.params.id
        await this.getGlass();


        productName=this.glass.name
        productPrice=this.glass.price
        productDescription=this.glass.description

        // if(this.language=="fr")
        // {

        //     if(this.$route.params.id==22)
        //     {
        //         productName="Chaï"
        //         productPrice='90'
        //         productDescription="Organique, légère et rétro, la monture Chaï s’inspire des silhouettes iconiques du Lower East side du Manhattan des années 70."
        //     }
        //     if(this.$route.params.id==25)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='570'
        //         productName="Louis infini"
        //         productDescription="Le modèle Louis Infini allie une monture en titane imprimée en 3D avec des inserts en bio-plastique et des branches en métal."
        //     }

        //     if(this.$route.params.id==26)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='3990'
        //         productName="Louis OP"
        //         productDescription="La Louis OP est le modèle phare de la Collection Louis, incarnant la simplicité futuriste et l’élégance historique."
        //     }

        //     if(this.$route.params.id==27)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='270'
        //         productName="Louis C"
        //         productDescription="Offrant un confort et une légèreté inégalée, la monture Louis C est un hommage aux penseurs, aux rêveurs, aux décideurs de notre époque."
        //     }

        //     if(this.$route.params.id==28)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='270'
        //         productName="Louis S"
        //         productDescription="Offrant un confort et une légèreté inégalée, la monture Louis S est un hommage aux penseurs, aux rêveurs, aux décideurs infatigables. "
        //     }
        //     if(this.$route.params.id==30)
        //     {
        //         brandName='Rêveur Lucide'
        //         productPrice='200'
        //         productName="RL2"
        //         productDescription="Offrant un confort et une légèreté inégalée, la monture Louis S est un hommage aux penseurs, aux rêveurs, aux décideurs infatigables. "
        //     }

        // }
        // else
        // {
        //     if(this.$route.params.id==22)
        //     {
        //         productPrice='90'
        //         productName="Chaï"
        //         productDescription="Organic, light, and with a retro flair, the Chaï frame draws its inspiration from the classic styles of Manhattan's Lower East Side in the 70s."
        //     }
        //     if(this.$route.params.id==25)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='570'
        //         productName="Louis infini"
        //         productDescription="The Louis Infinite model combines a titanium 3D-printed frame with bioplastic inserts and metal temples."
        //     }

        //     if(this.$route.params.id==26)
        //     {

        //         brandName='Meyrowitz'
        //         productPrice='3990'
        //         productName="Louis OP"
        //         productDescription="The Louis OP is the flagship model of the Louis Collection, embodying futuristic simplicity and historic sophistication."
        //     }

        //     if(this.$route.params.id==27)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='270'
        //         productName="Louis C"
        //         productDescription="Offering unparalleled comfort and lightness, the Louis C frame is a tribute to the thinkers, the dreamers, the relentless decision-makers."
        //     }

        //     if(this.$route.params.id==28)
        //     {
        //         brandName='Meyrowitz'
        //         productPrice='270'
        //         productName="Louis S"
        //         productDescription="Offering unparalleled comfort and lightness, the Louis S frame is a tribute to the thinkers, the dreamers, the relentless decision-makers. "
        //     }
        //     if(this.$route.params.id==30)
        //     {
        //         brandName='Rêveur Lucide'
        //         productPrice='200'
        //         productName="RL2"
        //         productDescription="Offering unparalleled comfort and lightness, the Louis S frame is a tribute to the thinkers, the dreamers, the relentless decision-makers. "
        //     }
        // }

        
        fbq('track', 'ViewContent', {
            content_ids: [this.$route.params.id], // 'REQUIRED': array of product IDs
            content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
        });
       
        document.querySelector('meta[name="description"]').setAttribute('content',productDescription)

        useMeta({
            title: "VISAGES - " + productName,
            
            htmlAttrs: { lang: this.language, amp: true },
            meta: [
                { property: 'og:title', content: productName },
                { property: 'og:description', content: productDescription },
                { property: 'og:url', content: 'https://visages.com/collection/'+this.$route.params.id },
                { property: 'og:image', content: this.glass.photos[0] },
                { property: 'product:brand', content: brandName },
                { property: 'product:availability', content: 'in stock' },
                { property: 'product:condition', content: 'new' },
                { property: 'product:price:amount', content: productPrice },
                { property: 'product:price:currency', content: 'EUR' },
                { property: 'product:retailer_item_id', content: this.$route.params.id },
                { property: 'product:item_group_id', content: 'glasses' }
                
              
            ],
        })
},

    async mounted() {
        if(this.$route.query.community){
     
            this.community=true
        }
        emitter.on('added-to-cart', function () {
            this.loaded = true
        }, this);
        emitter.on('close-share', function () {
            this.displayShare = false
        }, this);
       

        emitter.on('select-device-collectionglass', function () {
           
            this.activeSize='onmeasure'
            this.toggleSelect('custom');
            
            this.loaded = true
        }, this);

    

        this.localStorage = localStorage;
       
        
        // if(window.innerWidth<=760) {
        //     this.lastWindowY=window.innerHeight
        //     document.getElementById('right-content-collectionglass').style.marginTop='-'+window.innerHeight+'px'
        //     this.controller = new ScrollMagic.Controller();
        //     this.scene = new ScrollMagic.Scene({triggerElement: "#trigger1", duration: window.innerHeight,triggerHook: 0})
        //     .setPin("#left-content")
        //     //.addIndicators({name: "1 (duration: 300)"}) // add indicators (requires plugin)
        //     .addTo(this.controller);
        // }
        window.addEventListener('resize', this.initScene);

        window.addEventListener('scroll', this.handleScroll);

 
        // if(!this.$route.params.type && !this.$route.params.color && !this.$route.params.favorite) {
        //     console.log('pas de loader')
        //     this.photosLoaded=true
        //     this.loadingPhotos=false
        // }
            
        
        this.glassId = this.$route.params.id

        
        
        await this.getGlassTypes();
        await this.getGlassOptions();
        
        await this.getModelParts()
        await this.getColors()
        await this.getGlass();


        window.dataLayer  = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.productDetail',		// name of an event. In this case, always stays as eec.productDetail
            'ecommerce': {						// ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
                'currencyCode': 'EUR',		// the currency which is currently used by a visitor
                'detail': {						// name of an action. In this case, always stays as detail
                //'actionField': {'list': 'main'},    // optional. if this event happened in a certain list, pass its name with this key
                'products': [{
                    'name': this.glass.name,      // name of a product that is currently viewed. Always include it.
                    'id': this.glass.id,     		// id of a product. Always include it.
                    'price': this.glass.price.toFixed(2),			// price of a product
                    'brand': 'Visages',			// brand/vendor of a product
                    'category': 'Eyewear'	// category of a product
                   // 'variant': 'Silver'       	// product variant. If there are no variants, exclude this key from the dataLayer.push
                }]
                }
            }
        });

    

        await this.getTextures()
        await this.getBranches()
        

        if(!this.$route.params.type && !this.$route.params.color && !this.$route.params.favorite) {

            console.log('one')
            await this.selectTint(5,0);
            this.loadingGlass=false
            await this.calculatePhoto()
            this.loadingPhotos=false
            this.loaded = true
            this.displayLoadingShare=false
        }

        await this.getTintCategories()
        await this.getUserScans();
        await this.getRecommendedSize();
        await this.getSizes()
        await this.getReviews()

        if (this.$route.params.type) {

            if(this.$route.params.type=="solar") {

                console.log('two')

                this.displayTintChoice=true

                let indexsolar=0
                if(this.glass_options)
                {
                    for(let i=0;i<this.glass_options.length;i++)
                    {
                        if(this.glass_options[i].id==4)
                        {
                            indexsolar=i
                        }
                    }
                }

                await this.selectGlassOption(4, "Verres solaires", indexsolar, 20)
                
                
                await this.selectTint(4,0);
                this.loadingGlass=false
                await this.calculatePhoto()
                this.loadingPhotos=false
                //this.loaded = true
                this.displayLoadingShare=false

                console.log('display tint choice')
                console.log(this.displayTintChoice)
                
            }
            if(this.$route.params.type=="bluelight") {

                let indexbluelight=0
                if(this.glass_options)
                {
                    for(let i=0;i<this.glass_options.length;i++)
                    {
                        if(this.glass_options[i].id==3)
                        {
                            indexbluelight=i
                        }
                    }
                }
                
                await this.selectGlassOption(3, "Anti-lumière bleue (verres pour écran)", indexbluelight, 20)
            

                await this.selectTint(5,0);

                this.loadingGlass=false
                await this.calculatePhoto()
                this.loadingPhotos=false
                this.loaded = true
                this.displayLoadingShare=false
                
            }
            this.displayLoadingShare=false
        }


        //await this.calculatePhoto()
        //await this.calculateBranchPhoto()
        

        
      
        
        if (this.$route.params.color) {
            let color= await APIRequest.detail('colors',this.$route.params.color)
            this.loadingGlass=false
            
            this.activeColor = color.id
            if(!this.independantBranch) {
                this.activeBranchColor = color.id
            }
            this.activeColorPrice = color.price
            this.activeBranch = color.branchs[0].id
            this.activeBranchPrice = color.branchs[0].price
            this.loaded = true
            this.displayLoadingShare = false
        }

        if (this.$route.params.favorite) {
            console.log('on a un favo')
            await this.getFavorite();
            this.loadingGlass=false
            await this.calculatePhoto()
            //this.loadingPhotos=false
            //this.loaded = true
            this.displayLoadingShare=false
        }
        
        
        if (this.userScans.length > 0) {
            this.toggleSelect('custom');
            let default_scan=false
            for (let i = 0; i < this.userScans.length; i++) {
                if (this.userScans[i].default_scan == true) {
                    this.selectedScan = this.userScans[i].name
                    this.activeScan = this.userScans[i].id
                    default_scan=true
                }
            }
            if(default_scan==false){
                this.selectedScan = this.userScans[0].name
                this.activeScan = this.userScans[0].id
            }
        }
       
        
        this.slider = tns(
            this.tinySliderOptions
        );

        this.slider.events.on('indexChanged', this.checkEndSlider);

        this.slider.events.on('touchStart', this.dragStartSlider);
        this.slider.events.on('touchMove', this.dragStartSlider);

        this.slider.events.on('touchEnd', this.dragEndSlider);

        this.slider.events.on('dragStart', this.dragStartSlider);
        this.slider.events.on('dragMove', this.dragStartSlider);

        this.slider.events.on('dragEnd', this.dragEndSlider);

        
        this.loaded = true
        this.allIsLoaded=true
        // this.photosLoaded = true
        // this.loadingPhotos=false
        // this.allPhotosLoaded=true

        
        // if(this.glass.id==22)
        // {
        //     this.slider_creation = tns(
        //         this.creationSliderOptions
        //     );
        // }
    }
})
</script>
<style>
.loading-overlay .message
{
    position:absolute;
    text-align: center;
    animation:animloading 1s infinite ease-in-out;
    max-width: 90%;
 
    font-size:20px;
    color:#fff;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
@keyframes animloading
{
    from{
        opacity:1;
    }
    to {
        opacity:0;
    }
}
.loading-overlay
{
   
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    z-index: 9999999999;
    height:100vh;
    overflow: hidden;
    background:rgba(0,0,0,0.9);

}
#left-content .four .tns-nav button:nth-child(5){
    display:none !important;
}
.reviews-resume .note {
    display: flex;
}

.reviews-resume .number {
    font-size: 10px;
    color:#797979;
    font-weight:400;
    text-transform: none;
}

.reviews-resume {
    display: flex;
    font-weight: 400;
    font-size: 19px;
}

.share-section {

    text-align: center;
    padding-bottom: 10px;
}
.share-section a:nth-child(2) img
{
    margin-bottom:0;
}
.share-section a img {
    
    display: inline-block;
}
.ontopload
{
    position:absolute;
    z-index: 10;
}
.share-section a {
    font-weight: 400;
    text-align: center;
    margin-left: 15px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 15Px;
    font-size: 13px;
    color: #000;
    text-decoration: none;
}

.delivery-message {
    padding: 31px 15px;
    background: #F8F8F8;
    font-size: 16px;
    color:#020101;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.tns-slider {
    transition-timing-function: ease-out;
}

#next-creation {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 3;
}

#prev-creation img {
    transform: rotate(-180deg);
}

#prev-creation {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 3;
    transform: translateY(-50%);
}

.slider button {
    display: none !important;
}

.slider,
.slider-creations {
    position: relative;
}

.slider-creations.real .carousel-cell img {
    width: 100%;
    height: auto;
    border-radius: 13px;
}

.creations-content .title {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    font-size: 70px;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    transition: all 0.5s;
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #000;
    white-space: nowrap;
    color: transparent;
    text-decoration: none;
}

.image-collection {
    width: 100%;
    height: auto;
}

.line-bottom {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.line-bottom .right .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 75%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.line-bottom .left .text {
    font-size: 13px;
}

.line-bottom .left .title {
    font-size: 30px;
}

.line-bottom .left {
    width: 50%;
    padding-right: 5%;
    padding-bottom: 10%;
}

.line-bottom .right {
    width: 50%;
    position: relative;

}

.line-top {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    min-height: 50vh;
}

.line-top .left .image video {
    position: absolute;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.line-top .left .image {

    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.line-top .right .text {
    font-size: 13px;
}

.line-top .right .title {
    font-size: 30px;
}

.line-top .right {
    width: 50%;
    padding-left: 5%;
    padding-bottom: 10%;
}

.line-top .left {
    width: 50%;
    position: relative;

}
.tns-nav{
    text-align: center;
}
.tns-nav button
{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: .25;
    cursor: pointer;
    padding:0;
}
.tns-nav button.tns-nav-active
{
    opacity:1;
}

body .tns-nav button.tns-nav-active {
    height: 2px;
}

body .tns-nav {
    position: relative;
    top: -50px;
}

body .tns-nav button {
    width: 30px !important;
    border-radius: 0;
    height: 2px;
}

.scan-choice::before {
    position: absolute;
    content: ' ';
    width: 17px;
    height: 17px;
    background-size: 100% 100%;
    left: 10px;

    top: 10px;
    background-image: url('../../public/assets/images/icon_scan.svg');
}
.scan-choice.opened::after {
    transform: rotate(-90deg);
    right:-7px;
}
.bottom-product-collection
{
    position: relative;
    background:#fff;
    z-index: 3;
}
.scan-choice::after {
    position: absolute;
    content: ' ';
    width: 40px;
    transition:all 0.3s ease-out;
    height: 40px;
    transform: rotate(90deg);
    background-size: 100% 100%;
    right: 0px;
    color: #000;
    top: -3px;
    background-image: url('../../public/assets/images/arrow_scan.svg');
}

.title-size.scan {
    position: relative;
    left: 0px;
}
.title-top
{
    font-size:22px;
    position: absolute;
    text-align:center;
    top:15px;
    z-index: 3;
    max-width: 120px;
    line-height: 1;
    left:50%;
    transform: translateX(-50%);
    text-transform: uppercase;
}
.scan-choice {
    cursor: pointer;
    background: transparent linear-gradient(90deg, #5D2BFF 0%, #0000E0 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    text-align: left;
    left: 0px;
      padding-left: 40px;
    padding-right: 34px;
}

.collection .collab-section.product .right {
    padding-top: 80px;
}

.collection .collab-section.product {
    padding-top: 80px;
}

.collection .collab-section.product .left .full {
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 9;
}

.collection .collab-section.product .left.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999999999;
    width: 100%;
    height: 100%;
}
.collection .collab-section.product .left.fixed {
    background:rgba(255,255,255,0.6);
    backdrop-filter: blur(20px);
}
.collection .collab-section.product .left {
    position: relative;
    padding-left: 0%;
    margin-right: 5%;
    padding-right: 0;
    width: 54%;
    background: #F8F8F8;
    min-height: 80vh;
}

.collab-section.product .right p {
    font-size: 16px;
}
.collection .collab-section.product .right .collab-title.contenumob
{
    display: none;
}
.collection .collab-section.product .right .collab-title {
    text-align: left;
    display: inline-block;
    font-size: 32px;
}

.collection .collab-section.product .right .price .amount {
    font-size: 27px;
    font-weight:600;
}

.collection .collab-section.product .right .price {
    float: right;
    font-size: 15px;
    margin-top: 0px;
}

.collection .personnalisation .toggles .toggle-bg {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position: absolute;
    left: 0;
    height: 95%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 50%;
    transition: all 0.3s ease-in-out;
}
.collection .personnalisation .toggles .toggle.active {
    font-weight: 600;
}
.collection .personnalisation .toggles .toggle {
    display: inline-block;
    width: 49%;
    padding: 9px 11px;
    position: relative;
    font-size:15px;
    
    z-index: 2;
    vertical-align: top;
}

.collection .personnalisation .toggles {
    background: rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    text-align: center;
    position: relative;
    min-width: 235px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display: block;
    width: 235px;
    vertical-align: top;
    margin-bottom: 10px;
}

.collection .personnalisation {
    font-size: 12px;
    margin-top: 30px;
}

.size_choice {
    text-align: center;
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.size_choice .size.active {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.head .info .link {
    font-size: 13px;
    position: relative;
    top: 6px;
    float: right;
    font-weight: 600;
    color: rgba(103, 103, 103, 1);
    cursor:pointer;
}

.head .info {
    float: right;
    min-width:15px;
    display: inline-block;
    vertical-align: top;
    width: 15%;
    text-align: center;
    position: relative;
    right:0;
    top:0;
    position:absolute;
}

.size_choice .size {
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    cursor: pointer;
    width: 39.5px;
    height: 39.5px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: 3px;
    margin-right: 3px;
    color: rgba(103, 103, 103, 1);
}

.title-size {
    position: relative;
    top: 0;
    font-size: 13px;
    font-weight: 600;
    color: rgba(103, 103, 103, 1);
}
.select .glass_option_active {
    padding-right:5px;
}
.select {
    cursor: pointer;
    color: #020101;
    width: 100%;
    font-size:14px;
    opacity: 1;
    margin-bottom: 10px;
    text-align: center;
    padding-left: 30px;

    font-weight: 400;
    position: relative;

}

.personnalisation-options {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 20px;
    border-radius:30px;
    padding-top: 15px;
    margin-bottom:20px;
    padding-bottom: 15px;
}
 .personnalisation-options .options .option-title.active .option-underline{
    width: 100%;
     transition: all 0.3s ease-in-out;
 }
.personnalisation-options .options .option-underline {
    width:0;
    height: 2px;
    background: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}
.personnalisation-options .options .option-title.active {
    font-weight:600;
}

.personnalisation-options .options .option-title {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:0;
    padding-right:0;
    vertical-align: top;
    white-space: nowrap;
    position: relative;
    font-weight: 400;
    min-width: 110px;
    width: 25%;
    text-align: center;
    font-size: 17px;
    border-bottom: 1px solid rgba(103, 103, 103, 0.2);
}

.personnalisation-options .options {
    padding-left:40px;
    padding-right:40px;
    position: relative;
    flex-direction: row;
    width:100%;
    align-items: flex-end;
    justify-content: center;
}

.option-list .option:hover {
    background: rgba(0, 0, 0, 0.05);
}

.option.disabled {
    opacity: 0.5;
}

.option-list .option {
    padding-left: 50px;
    text-align: left;
    font-size:14px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 5;
    cursor: pointer;
}

.select-block {
    overflow:hidden;
    position: relative;
    padding-top: 11px;
    padding-bottom: 2px;
    background: rgba(118, 118, 128, 0.09) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-top: 20px;
}

.option-list {
    position: relative;
    left: 0;
    top: 0;

    width: 100%;
    z-index: 9999;
}

.select::after {
    content: ' ';
    width: 25px;
    height: 25px;
    
    border-radius:50%;
    background-image: url(../../public/assets/images/arrow-bottom-new.png);
    background-position:center center;
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: absolute;
    right: 17px;
    transition:all 0.3s;
    top: -3px;
    display: block;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.select.opened::after {
    transform:rotate(180deg);
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
}

.personnalisation-content .item.style.active .title-item {
    color: #fff;

}

.personnalisation-content .item.style.active {
    background: #000;
}

.personnalisation-content .item.style {
    border: 1px solid black;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 27px;
}

.personnalisation-content .item.texture_style {
    text-align: center;
    min-width: 70px;
    word-wrap: normal;
}

.personnalisation-content .item.branchcolor .img {
    position: relative;
    width: 28px;
    height: 28px;
    top:2px;
    left:2px;
}
.personnalisation-content .item .img {
    position: relative;
    width: 40px;
    height: 40px;
    
    border-radius: 50%;
    position: relative;
}

.personnalisation-content .item.active .round {
    border: 1px solid black;
}

.subitems {
    margin-top: 20px;
}

.personnalisation-content .item.texture_style.active .img {
    position: relative;
    width: 36px;
    height: 36px;
    border: 1px solid black;
    border-radius: 50%;
    position: relative;
}

.personnalisation-content .item.texture_style .img img {
    width: 30px;
    position: relative;
    left: 0;
    top: 2px;

    height: 30px;
}

.personnalisation-content .item.texture_style .img {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
}
.personnalisation-content .item.tint {
    min-width:55px;
}
.personnalisation-content .item .img {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    top:4px;
    left:4px;
}

.item.color .title-item {
    margin-top: 5px;

}

.personnalisation-content .item.color .img img {
    position: relative;
    left: 0;
    top: 2px;
}

.personnalisation-content.branchs .item .add {
    margin-left: auto;
    margin-right: auto;
    max-width: 80px;

}

.personnalisation-content.branchs .item img {
    width: 148px;
    margin-left: auto;
    margin-right: auto;
}
.personnalisation-content .item.color .img {
   
    border:1px solid #ccc;
}
.personnalisation-content .item img {
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    position:relative;
   
    top:4px;
   
}

.personnalisation-content .item .add .add-button {
    display: inline-block;
    vertical-align: top;
    padding-top: 10px;
    color: rgba(103, 103, 103, 1);
    padding-bottom: 10px;
}

.personnalisation-content .item .add .price-item {
    display: inline-block;
   
    vertical-align: top;
    padding-top: 5px;
    padding-bottom: 5px;
}

.personnalisation-content .item .add.active {
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.personnalisation-content .item .add {
    width: auto;
    cursor: pointer;
    display: block;
    display: inline-block;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding-right:10px;
    padding-left:10px;
}

.actions {
    padding-top: 15px;
    padding-bottom: 0;
    border-top: 1px solid #e1dede;
    display:flex;
    align-items: center;
    justify-content: center;
}

.personnalisation-content .item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-right: 10px;
    margin-top:40px;
}

.actions .add-to-cart {
    float: none;
}

.actions .price_total {
    font-size: 32px;
    margin-right:70px;
    font-weight:600;
}

.personnalisation-content {
    padding-top: 20px;
    text-align: center;
    display: block;
    padding-left:40px;
    padding-right:40px;
    width: 100%;
}

.collection input.input-gravure {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 0.5px solid rgba(112, 112, 112, 1);
}

.flickity-page-dots .dot {
    border-radius: 0;
    width: 50px;
    height: 2px;
}

.size_choice a {
    color: rgba(16, 132, 255, 1);
}

.size_choice {
    font-size: 13px;
}

.personnalisation-options .options .option-title {
    position: relative;
}

.personnalisation-options .options .option-title .price-option {
   
    font-size: 10px;
    margin-left:5px;
    color:#A5A5A5;
    position: absolute;
    bottom:7px;
    white-space: nowrap;
}
.gravure-container
{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}
.text_preview .gravure_img {
    position: relative;
    
}

.text_preview {
    position: absolute;
    min-height: 80vh;
    width: 100%;
    top:0;
    height:100%;
 
}



.left.fixed .gravure_preview.glass25 {
    font-size: 2.2vw;
    transform: translate(-10.5vw, 0.6vw) rotate(31deg);
    
}

.left.fixed .gravure_preview {
   
    font-size: 3vw;
    transform: translate(-5vw, -12vw);
    
}

.gravure_preview.glass25
{
    
    text-align: center;

    font-size: 1.1vw;
    transform: rotate(31deg);
    
   
    text-shadow: 0px 0px rgba(255, 255, 255, 0.1), -1px -1px #444;
    color: #000;
    mix-blend-mode: normal;
    color: rgba(0, 0, 0, 0);
   
}
.gravure_preview.glass26
{
    
    text-align: center;
    
    font-size:1.2vw;
   
}
.gravure_preview {

    text-shadow: 1px 1px rgba(255, 255, 255, 0.5), -1px -1px #444;
    position: absolute;
    top: 50%;
    left: 50%;
    width:33%;
    text-align: center;
   
    z-index: 3;
    font-size: 1.5vw;
    font-weight: 600;
    letter-spacing: 1px;
    color: #000;
    mix-blend-mode: overlay;
}
@media screen and (min-width:1000px)
{
    .item.color:hover .title-item {
    opacity: 1;
}
}

@media screen  and (min-width:1024px) and (max-width:1110px) {
    .gravure_preview {
        font-size: 2vw;
    }
    .gravure_preview.glass26
    {
        font-size: 1.8vw !important;
    }

}


@media screen  and (min-width:1024px) and (max-width:1260px) {
    /* .gravure_preview {
     
        
         transform: translate(-5vw, -6.4vw);
    } */

}
@media screen and (min-width:1260px) and (max-width:1500px) {
    /* .gravure_preview {
         transform: translate(-5vw, -6.4vw);
    } */

}

.carousel-cell {}

.tint-options .option-title.active {
    font-weight: 600;
    border-bottom: 2px solid black;
}

.collab-section.product .actions .add-to-cart {
    font-size: 15px;
}

.tint-options .option-title {
    cursor: pointer;
    padding: 5px 10px;
    vertical-align: top;
    display: inline-block;
    margin-top: -10px;
    white-space: nowrap;
    position: relative;
    width: auto !important;
    min-width:10px !important;
    text-align: center;
    font-size: 12px;
    border-bottom: 1px solid rgba(103, 103, 103, 0.2);
}

.select img {
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom;
}

.option-list .option img {
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom;
}

.item .add {
    font-size: 10px;
}



.item.color.active .title-item {
    opacity: 1;
   
}

.item.color .title-item {
    opacity: 0;
}

.title-item {
    font-size: 11px;
    margin-top: 3px;
    font-weight: 400;
    margin-bottom: 3px;
}

.slider-content-hide {
    display: none;
}

#prev[aria-disabled='true'],
#next[aria-disabled='true'] {
    opacity: 0.4;
}

#next {
    transform: translateY(-50%) rotate(-90deg);
    right: 50px;
}

#prev {
    transform: translateY(-50%) rotate(90deg);
    left: 50px;
}

#prev,
#next {
    position: absolute;
    top: 50%;
    padding:5px;
}


.collection .collab-section.product .left {
    /*max-height: 100vh;*/
}

.collection .collab-section.product .left.fixed .tns-nav {
    transform: translateY(-50vh);
}
.collection .collab-section.product .left.fixed .iiz.img {
    position :relative;
    left:50%;
    transform:translateX(-50%);
}

.collection .collab-section.product .left.fixed .carousel-cell img.img.render-layer2 {
 
    left:50%;
    transform: translateX(-50%);
}
.collection .collab-section.product .left.fixed .carousel-cell img.img,
.collection .collab-section.product .left.fixed .carousel-cell .img .iiz__img {
    min-height: 150vh;
    max-height: 150vh;
    position: relative;
    top: -25vh;
    
}

.collection .collab-section.product .left .carousel-cell img {
    /*max-height: 100vh;*/
    object-fit: contain;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.loader {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
}

.loader img {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popup-size .size-description .bold {
    font-weight: 600;
    text-decoration: underline;
}

.popup-size .size-description a {
    text-decoration: underline;
    color: #000;
}

.popup-size .size-description {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popup-size .size_choice {
    width: 100%;
}

.popup-size .size-name {
    color: #676767;
    font-weight: 600;
    margin-bottom: 10px;
}

.popup-size #cross {
    position: absolute;
    right:-10px;
    top: -10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 8px;
    background: #fff;
    border-radius: 50%;
    cursor:pointer;
}
.popup-size {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:9999;
    background:rgba(0, 0, 0, 0.5);
}
.popup-size .content {
    background: #fff;
    padding: 20px;
    border-radius: 11px;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 440px;
    transform: translate(-50%,-50%);
    z-index: 8;
    font-size: 13px;
    min-width: 440px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
#trigger1 {
    position:absolute;
    top:95.5px;
}

@media screen and (max-width:760px) {
    .loading-overlay .message
    {
        min-width: 80%;
    }
    body .gravure_preview {
       
        font-size: 2vw !important;
    }

    #site-header.fixed.hidden.collectionglass
    {
        margin-bottom: -100px;
    }
    #site-header.collectionglass
    {
        
    }
    #site-header.collectionglass.nofixed
    {
        box-shadow:none !important;
        position: absolute !important;
        background:transparent !important;
    }
    #site-header.collectionglass.fixed
    {
        position: fixed !important;
    }
    .collection .collab-section.product .left.fixed .tns-nav {
        transform: translateY(-53vh) !important;
    }

  
    #popup-login .content .left {
        width:100% !important;
        z-index:1;
        height:120px !important;
        position:relative !important;

    }
    #popup-login .content .right {
        width:100% !important;
        left:0 !important;
        z-index: 2;
        
    }
}

</style>